@import '/src/styles/_helpers.scss';

.iconLabelAmountContainer {
  @include flex-xy(flex-end, center);
  flex-direction: column;
  color: var(--transfer-text);

  .ilaImageContainer {
    @include flex-xy;
    flex-direction: column;
    width: 36%;
    height: 46%;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .ilaAmountContainer {
    @include flex-xy;
    background: var(--transfer-amount);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
