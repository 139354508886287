@import '/src/styles/_helpers.scss';

.sidebarWrapper {
  position: relative;
  width: 22%;
  font-weight: 400;
  border: #8d7c4f 0.01rem solid;
  overflow-y: auto;
  background: var(--home-bg-sidebar) 100% 100% no-repeat;

  .sidebarItems {
    @include flex-xy(flex-start, center);
    position: relative;
    height: 0.54rem;
    width: 100%;

    &::after {
      content: "";
      height: 0.01rem;
      width: 100%;
      position: absolute;
      bottom: 0;
      background: var(--sidebar-outline-stroke);
    }

    .imgHolder {
      @include flex-xy;
      margin-left: 0.3rem;
      height: 0.3rem;
      width: 0.3rem;
      border: 0.03rem solid white;
      border-radius: 50%;
      overflow: hidden;
      position: relative;
      img {
        height: 125%;
        width: 125%;
      }
    }

    > .txt {
      background: -webkit-linear-gradient(#ffffff, #fff6c2);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-size: 0.15rem;
      color: white;
      font-weight: 700;
      margin-left: 0.2rem;
    }
    span {
      background: var(--common-text-unactive);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-size: 0.15rem;
      color: white;
      font-weight: 700;
    }

    .recommend {
      @include flex-xy;
      background-color: #ea2227;
      height: 0.15rem;
      width: 0.15rem;
      position: absolute;
      top: 0.05rem;
      right: 0;
      border-radius: 0.05rem 0 0 0.05rem;
      > span {
        @include flex-xy;
        position: relative;
        top: -0.01rem;
        height: 100%;
        width: 100%;
        color: #fff !important;
        font-size: 0.09rem;
      }
    }
    &.activeItem {
      background: var(--sidebar-bg-active) no-repeat;
      background-size: 100% 100%;
      span {
        background: var(--common-text-active);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
    &.noIconOveray {
      justify-content: center;
      span {
        font-size: 0.2rem;
      }
    }
  }
}
