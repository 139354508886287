.container {
  overflow-y: scroll;
  height: 100%;
  width: 100%;

  .vipDetails_wrapper {
    font-size: 0.25rem;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .vipDetails_header {
      margin-bottom: 0.05rem;

      span {
        background: var(--vip-header-title);
        background-clip: text;
        -webkit-text-fill-color: transparent;
        text-align: center;
        font-weight: 500;
      }
    }

    .vipDetails_body {
      flex: 1;
      font-size: 0.15rem;
      width: 90%;
      height: 100%;
      overflow-y: scroll;

      &::-webkit-scrollbar {
        display: none;
      }
      :global {
        table {
          thead {
            tr {
              th {
                &:not(:last-child) {
                  border-right: var(--vip-right-bar) 0.001rem solid;
                }
              }
            }
          }
          tbody {
            tr {
              td {
                &:not(:last-child) {
                  border-right: var(--vip-right-bar) 0.001rem solid;
                }
              }
            }
          }
        }
      }
    }
  }

  &[data-theme='yellowWhite'] {
    .vipDetails_body {
      :global {
        table {
          thead {
            tr {
              th {
                color: #ffc400 !important;
              }
            }
          }
          tbody {
            tr {
              &:nth-child(odd) {
                background: #f1f1f1;
              }
              &:nth-child(even) {
                background: #fefefe;
              }
            }
          }
        }
      }
    }
  }
  &[data-theme='whiteGold'] {
    .vipDetails_body {
      :global {
        table {
          thead {
            tr {
              th {
                &:not(:last-child) {
                  border-right: none;
                }
              }
            }
          }
          tbody {
            tr {
              td {
                &:not(:last-child) {
                  border-right: none;
                }
              }
            }
          }
        }
      }
    }
  }
}

.vipDetails_body {
  .vipDetails_tableHeader {
    width: 6.2rem;
    background: linear-gradient(to top, #2c2c2c, #1a1a1a);
    display: flex;

    ul {
      display: flex;
      list-style: none;

      li {
        width: 1.2rem;
        text-align: center;
        padding: 0.0155rem;
        border-right: 0.0001rem solid rgba(65, 65, 65, 0.685);
      }
    }
  }
}
