.ulbBox_wrapper {
  width: 2rem;
  height: 1rem;
  text-align: center;
  padding: 0.04rem;
  border-radius: 1px !important;
  font-weight: 400;
  font-size: 0.1rem;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.ulbBox_header {
  height: 0.25rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-right-radius: 0.08rem;
  border-top-left-radius: 0.08rem;
  gap: 0.05rem;
  img {
    width: 0.13rem;
    height: 0.13rem;
  }
}

.ulbBox_body {
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .ulbBox_ycount {
    font-size: 0.25rem;
  }

  border-bottom-right-radius: 0.08rem;
  border-bottom-left-radius: 0.08rem;
}

.ulbBox_info {
  font-size: 0.1rem;
  background-color: #888888;
  border-radius: 0.2rem;
  width: 0.7rem;
  margin: auto;
  padding: 0.02rem 0.01rem 0.02rem;
}
.isActive {
  background: linear-gradient(to bottom, #ffe800, #e38c00);
}

.ulbBox_container {
  display: flex;
}
