body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    /*NOT YET DONE DONT TOUCH :D*/
    /*WILL DELETE DUPLICATE AFTER ALL CHANGES*/
    #root {
        --common-text-active: #511a00;
        --common-text-unactive: linear-gradient(#fff, #fff6c2);
        --common-header: #fff;
        --vip-header: #fed500;
        --balance-header: #fff;
        --balance-outline-header: linear-gradient(0deg, #ffeda7, #dec18c);
        --balance-bg-header: #20201e;
        --vip-outline-bar: linear-gradient(0deg, #ffeda7, #dec18c);
        --vip-bg-bar: #0a0a0a;
        --vip-progress-bar: linear-gradient(180deg, #fee587, #ffb02b);
        --vip-header-title: linear-gradient(#fff, #f9c423);
        --vip-right-bar: #444444;
        --settings-header: linear-gradient(180deg, #ffffff 0%, #fce9c9 86.3%);
        --setting-password-label: #ffffff;
        --setting-bg-content: #1f1e1b;
        --setting-bg-basic-info: #2c2c2c;
        --setting-music-range: #00000080;
        --setting-music-border: #8d7c4f;
        --setting-bg-basic-text: #fff;
        --setting-header-title-text: #651d00;
        --setting-profile-border: #feeba6;
        --setting-bg-input: #000000;
        --profile-bg: #dec28c;
        --footer-text-list: linear-gradient(#fff, #f9d57a);
        --footer-dialog-text: -webkit-linear-gradient(#fff, #f9d57a);
        --home-text-unactive: linear-gradient(#fff, #fff6c2);
        --home-text-active: #511a00;
        --home-marquee: #fff;
        --home-placeholder-search: #757575;
        --home-input-search: #fff;
        --table-header: linear-gradient(to top, #ffe29b, #fff);
        --record-activeNav-bg: linear-gradient(to right, #fceba5, #d5a93d);
        --record-activeNav-after-bg: linear-gradient(to top, #ffeda7, #dec18c);
        --record-dropdownLabel-color: #cdcaca;
        --record-roundedTabs-bg: #1a1a1a;
        --record-roundedTabs-color: #fff;
        --home-bg-sidebar: linear-gradient(270deg, #3c3a3b 0%, #1a1a1a 100%);
        --sidebar-outline-stroke: linear-gradient(to left, #ffffff00, #fffa92, #ffc884, #fffa92, #ffffff00);
        --promotion-text-title: #fff;
        --promotion-bg-title: #363636;
        --promotion-text-input: #cdcaca;
        --promotion-bg-input: #1a1a1a;
        --security-header-border: #525252;
        --security-title-bg: linear-gradient(to right, #222222, #444444);
        --vip-balance-bg: #2c2c2c;
        --vip-balance-amount: #42f166;
        --vip-btn-text: #511a00;
        --home-bg-color: #000;
        --nodata-bg: -webkit-linear-gradient(#ffffff, #fff6c2);
        --sidebar-modal-bg: linear-gradient(270deg, #3c3a3b 0%, #1a1a1a 100%);
        --sidebar-modal-border: #8d7c4f;
        --promotion-lower-bg: #1a1a1a;
        --promotion-lower-title: -webkit-linear-gradient(#fedf7f, #bf8734);
        --promotion-lower-sub: #fff6c2;
        --transfer-text: #fff;
        --transfer-amount: #ffb02b;
        --sidebar-modal-divider: url('../app/assets/blackGold/Sidebar/divider.png');
        --sidebar-modal-active-bg: url('../app/assets/blackGold/Sidebar/btn-vipactive.png');
        --vip-btn-bg: url('../app/assets/commons/button1.png');
        --header-bg-img: url('../app/assets/blackGold/header/home_header.png');
        --footer-bg-img: url('../app/assets/blackGold/footer/bottom-bg.webp');
        --footer-dialog-box: url('../app/assets/blackGold/footer/MoreBalloon.png');
        --home-bg-title-sidebar: url('../app/assets/blackGold/main/DottedSideBar.png');
        --home-title-sidebar: url('../app/assets/blackGold/main/sidebar_title.png');
        --home-bg-active: url('../app/assets/blackGold/main/sb_active_item.png');
        --home-bg-search: url('../app/assets/blackGold/main/search-bg.png');
        --header-bg-inner: url('../app/assets/blackGold/header/header_bg.png');
        --sidebar-bg-active: url('../app/assets/commons/btn-vipactive.png');
        --setting-bg-header: url('../app/assets/blackGold/other_modal/OtherModalHeader.png');
        --close-modal-button: url('../app/assets/blackGold/other_modal/othermodal_xbtn.png');
        --setting-pop-logo: url('../app/assets/blackGold/other_modal/poplogo.png');
        --accordion-body-color: #fff;

        &[data-theme='brownGold'] {
            --balance-bg-header: #875b32;
            --vip-bg-bar: #5e3e22;
            --home-bg-sidebar: linear-gradient(270deg, #634126 0%, #432b0a 100%);
            --home-placeholder-search: #fff;
            --table-header: linear-gradient(to top, black, black);
            --record-activeNav-bg: linear-gradient(to right, #735c4b, #735c4b);
            --record-activeNav-after-bg: linear-gradient(to left, #735c4b, #735c4b);
            --record-dropdownLabel-color: #000000;
            --record-roundedTabs-bg: #fff;
            --record-roundedTabs-color: #735c4b;
            --promotion-bg-title: #c4a994;
            --promotion-text-input: #888888;
            --promotion-bg-input: #ededed;
            --security-header-border: #c4a994;
            --security-header-bg: #fff;
            --security-title-bg: linear-gradient(to right, #c4a994, #c4a994);
            --vip-balance-bg: #c4a994;
            --vip-balance-text: #fff;
            --vip-balance-amount: #00ff00;
            --vip-header-title: #735c4b;
            --vip-right-bar: #f3e3da;
            --nodata-bg: #bfbfbf;
            --sidebar-modal-bg: linear-gradient(270deg, #634126 0%, #432b0a 100%);
            --promotion-lower-bg: #ededed;
            --promotion-lower-title: #735c4b;
            --promotion-lower-sub: #888;
            --transfer-amount: #fee587;
            --setting-password-label: #0b0b0b;
            --setting-bg-content: #ffffff;
            --setting-bg-basic-info: #c4a994;
            --setting-bg-basic-text: #fff;
            --setting-profile-border: #feeba6;
            --setting-bg-input: #ededed;
            --sidebar-modal-divider: url('../app/assets/brownGold/Sidebar/divider.png');
            --sidebar-modal-active-bg: url('../app/assets/brownGold/Sidebar/btn-vipactive.png');
            --header-bg-img: url('../app/assets/brownGold/header/home_header.png');
            --footer-bg-img: url('../app/assets/brownGold/footer/bottom-bg.webp');
            --footer-dialog-box: url('../app/assets/brownGold/footer/MoreBalloon.png');
            --home-bg-title-sidebar: url('../app/assets/brownGold/main/DottedSideBar.png');
            --home-title-sidebar: url('../app/assets/brownGold/main/sidebar_title.png');
            --home-bg-active: url('../app/assets/brownGold/main/sb_active_item.png');
            --home-bg-search: url('../app/assets/brownGold/main/search-bg.png');
            --header-bg-inner: url('../app/assets/brownGold/header/header_bg.png');
            --sidebar-bg-active: url('../app/assets/brownGold/Sidebar/btn-vipactive.png');
            --setting-bg-header: url('../app/assets/brownGold/other_modal/OtherModalHeader.png');
            --close-modal-button: url('../app/assets/brownGold/other_modal/othermodal_xbtn.png');
            --setting-pop-logo: url('../app/assets/brownGold/other_modal/poplogo.png');
            --accordion-body-color: #000000;
        }

        &[data-theme='yellowWhite'] {
            --common-text-active: #a11300;
            --common-text-unactive: #0a0a0a;
            --common-header: #0a0a0a;
            --vip-header: #0a0a0a;
            --balance-header: #c5915a;
            --balance-bg-header: #fff;
            --balance-outline-header: #fff;
            --settings-header: linear-gradient(180deg, #bea288 0%, #a11300 100%);
            --setting-password-label: #a11300;
            --setting-bg-content: rgb(245, 245, 245);
            --setting-music-range: #00000080;
            --setting-bg-basic-info: #0a0a0a;
            --setting-bg-basic-text: #ffc400;
            --setting-profile-border: #feeba6;
            --setting-bg-input: #fefeff;
            --vip-outline-bar: #0a0a0a;
            --vip-progress-bar: #fff;
            --vip-header-title: linear-gradient(#0a0a0a, #0a0a0a);
            --vip-right-bar: #ffc400;
            --profile-bg: linear-gradient(150deg, #ffffff, #0a0a0a) border-box;
            --footer-text-list: linear-gradient(180deg, #bea288 0%, #a11300 100%);
            --home-bg-sidebar: linear-gradient(90deg, #ffb800 8.68%, #fefeff 110.99%);
            --home-text-unactive: #0a0a0a;
            --home-text-active: #a11300;
            --home-marquee: #fefeff;
            --home-placeholder-search: #757575;
            --home-input-search: #000;
            --table-header: linear-gradient(to top, black, black);
            --record-activeNav-bg: linear-gradient(to right, #ffc400, #ffc400);
            --record-activeNav-after-bg: linear-gradient(to top, #ffeda7, #dec18c);
            --record-dropdownLabel-color: #a11300;
            --record-roundedTabs-bg: #1a1a1a;
            --record-roundedTabs-color: #ffc400;
            --sidebar-outline-stroke: linear-gradient(to left, #3beaff00, #c5915a, #3beaff00);
            --promotion-text-title: #ffc400;
            --promotion-bg-title: #0a0a0a;
            --promotion-text-input: #bea288;
            --promotion-bg-input: #ebedf1;
            --security-header-border: #ffc400;
            --security-header-bg: #fff;
            --security-title-bg: linear-gradient(to right, #ffc400, #ffc400);
            --vip-balance-bg: #0a0a0a;
            --vip-balance-text: #face13;
            --vip-balance-amount: #00ff00;
            --nodata-bg: #c5915a;
            --sidebar-modal-bg: linear-gradient(269.32deg, #fefeff 0.71%, #ffb800 97.25%);
            --sidebar-modal-border: #ffc400;
            --promotion-lower-bg: #ebedf1;
            --promotion-lower-title: #a11300;
            --promotion-lower-sub: #bea288;
            --transfer-amount: linear-gradient(90deg, #fee587 0%, #ffb02b 100%);
            --sidebar-modal-divider: url('../app/assets/yellowWhite/Sidebar/divider.png');
            --sidebar-modal-active-bg: url('../app/assets/yellowWhite/Sidebar/btn-vipactive.png');
            --header-bg-img: url('../app/assets/yellowWhite/header/home_header.png');
            --footer-bg-img: url('../app/assets/yellowWhite/footer/bottom-bg.webp');
            --footer-dialog-box: url('../app/assets/yellowWhite/footer/MoreBalloon.png');
            --home-bg-title-sidebar: url('../app/assets/yellowWhite/main/DottedSideBar.png');
            --home-title-sidebar: url('../app/assets/yellowWhite/main/sidebar_title.png');
            --home-bg-active: url('../app/assets/yellowWhite/main/sb_active_item.png');
            --home-bg-search: url('../app/assets/yellowWhite/main/search-bg.png');
            --header-bg-inner: url('../app/assets/yellowWhite/header/header_bg.png');
            --sidebar-bg-active: url('../app/assets/yellowWhite/Sidebar/btn-vipactive.png');
            --setting-bg-header: url('../app/assets/yellowWhite/other_modal/OtherModalHeader.png');
            --close-modal-button: url('../app/assets/yellowWhite/other_modal/othermodal_xbtn.png');
            --setting-pop-logo: url('../app/assets/yellowWhite/other_modal/poplogo.png');
            --accordion-body-color: #435689;
        }

        &[data-theme='greenYellow'] {
            --balance-header: #facd12;
            --balance-outline-header: linear-gradient(180deg, #ffea01, #df8e00);
            --balance-bg-header: #063a32;
            --settings-header: linear-gradient(180deg, #ffffff 25.19%, #facd12 80.14%);
            --setting-password-label: #511a00;
            --setting-bg-content: #d6eae4;
            --setting-bg-basic-info: #0e9473;
            --setting-bg-basic-text: #face13;
            --setting-profile-border: #ffea01;
            --setting-bg-input: #fefeff;
            --vip-outline-bar: #e29300;
            --vip-bg-bar: #013629;
            --vip-progress-bar: linear-gradient(180deg, #ffea01, #e29300);
            --vip-header-title: linear-gradient(#511a00, #511a00);
            --vip-right-bar: #fff;
            --profile-bg: linear-gradient(300deg, #df8e00, #ffea01) border-box;
            --footer-text-list: linear-gradient(180deg, #ffffff 25.19%, #facd12 80.14%);
            --footer-dialog-text: linear-gradient(180deg, #fff 25.19%, #facd12 80.14%);
            --home-bg-sidebar: linear-gradient(90deg, #06362d 21.87%, #055848 71.67%, #007658 100%);
            --home-text-unactive: #fff;
            --home-placeholder-search: #b6b6b6;
            --table-header: #000;
            --record-activeNav-bg: linear-gradient(to bottom, #ffffff, #ffe800, #e39300);
            --record-activeNav-after-bg: linear-gradient(to left, #ffea01, #df8e00);
            --record-dropdownLabel-color: #511a00;
            --record-roundedTabs-bg: #ffffff;
            --record-roundedTabs-color: #0b5642;
            --promotion-bg-title: #3b8675;
            --promotion-text-input: #bebebe;
            --promotion-bg-input: #fff;
            --security-header-border: #0e9473;
            --security-header-bg: #f2f2f2;
            --security-title-text: #face13;
            --security-title-bg: linear-gradient(to right, #0e9473, #0e9473);
            --vip-balance-bg: #0e9473;
            --vip-balance-text: #face13;
            --vip-balance-amount: #00ff00;
            --nodata-bg: #facd1280;
            --sidebar-modal-bg: linear-gradient(90deg, #06362d 21.87%, #055848 71.67%, #007658 100%);
            --sidebar-modal-border: none;
            --promotion-lower-bg: #3b8675;
            --promotion-lower-title: #face13;
            --promotion-lower-sub: #fff;
            --transfer-amount: linear-gradient(180deg, #ffffff 0%, #fffae9 0.01%, #face13 27.6%);
            --sidebar-modal-divider: url('../app/assets/greenYellow/Sidebar/divider.png');
            --sidebar-modal-active-bg: url('../app/assets/greenYellow/Sidebar/btn-vipactive.png');
            --header-bg-img: url('../app/assets/greenYellow/header/home_header.png');
            --footer-bg-img: url('../app/assets/greenYellow/footer/bottom-bg.webp');
            --footer-dialog-box: url('../app/assets/greenYellow/footer/MoreBalloon.png');
            --home-bg-title-sidebar: url('../app/assets/greenYellow/main/DottedSideBar.png');
            --home-title-sidebar: url('../app/assets/greenYellow/main/sidebar_title.png');
            --home-bg-active: url('../app/assets/greenYellow/main/sb_active_item.png');
            --home-bg-search: url('../app/assets/greenYellow/main/search-bg.png');
            --header-bg-inner: url('../app/assets/greenYellow/header/header_bg.png');
            --sidebar-bg-active: url('../app/assets/greenYellow/Sidebar/btn-vipactive.png');
            --setting-bg-header: url('../app/assets/greenYellow/other_modal/OtherModalHeader.png');
            --close-modal-button: url('../app/assets/greenYellow/other_modal/othermodal_xbtn.png');
            --setting-pop-logo: url('../app/assets/greenYellow/other_modal/poplogo.png');
        }

        &[data-theme='skyBlue'] {
            --balance-outline-header: #3beaff;
            --balance-bg-header: #0071a9;
            --vip-outline-bar: transparent;
            --vip-bg-bar: #0071A9;
            --footer-text-list: linear-gradient(180deg, #ffffff 0%, #fce9c9 86.3%);
            --footer-dialog-text: linear-gradient(180deg, #fff, #fce9c9 86.3%);
            --home-bg-sidebar: #0081ca;
            --home-marquee: #888888;
            --home-placeholder-search: #fff;
            --table-header: #000;
            --record-activeNav-bg: linear-gradient(to right, #15d9f8, #037dfb);
            --record-activeNav-after-bg: none;
            --record-dropdownLabel-color: #888888;
            --record-roundedTabs-bg: #ffffff;
            --record-roundedTabs-color: #888888;
            --sidebar-outline-stroke: linear-gradient(to left, #3beaff00, #3beaff, #3beaff00);
            --promotion-bg-title: #0ca0e9;
            --promotion-text-input: #888888;
            --promotion-bg-input: #fff;
            --security-header-border: #0ca0e9;
            --security-header-bg: #f4f9f9;
            --security-title-bg: linear-gradient(to right, #0ca0e9, #0ca0e9);
            --vip-balance-bg: #0ca0e9;
            --vip-balance-text: #fff;
            --vip-balance-amount: #fff;
            --vip-header-title: linear-gradient(#0ca0e9, #0ca0e9);
            --vip-right-bar: #f4f6f9;
            --nodata-bg: #bfbfbf;
            --sidebar-modal-bg: #0081ca;
            --sidebar-modal-border: none;
            --promotion-lower-bg: #fff;
            --promotion-lower-title: #0ca0e9;
            --promotion-lower-sub: #888;
            --setting-password-label: #0b0b0b;
            --setting-bg-content: #f4f6f9;
            --setting-music-range: #00000040;
            --setting-bg-basic-info: #0ca0e9;
            --setting-bg-basic-text: #fff;
            --setting-header-title-text: #fff;
            --setting-profile-border: #feeba6;
            --setting-bg-input: #ffffff;
            --transfer-amount: linear-gradient(180deg, #ffffff 0%, #fffae9 0.01%, #face13 27.6%);
            --sidebar-modal-divider: url('../app/assets/skyBlue/Sidebar/divider.png');
            --sidebar-modal-active-bg: url('../app/assets/skyBlue/Sidebar/btn-vipactive.png');
            --header-bg-img: url('../app/assets/skyBlue/header/home_header.png');
            --footer-bg-img: url('../app/assets/skyBlue/footer/bottom-bg.webp');
            --footer-dialog-box: url('../app/assets/skyBlue/footer/MoreBalloon.png');
            --home-bg-title-sidebar: url('../app/assets/skyBlue/main/DottedSideBar.png');
            --home-title-sidebar: url('../app/assets/skyBlue/main/sidebar_title.png');
            --home-bg-active: url('../app/assets/skyBlue/main/sb_active_item.png');
            --home-bg-search: url('../app/assets/skyBlue/main/search-bg.png');
            --header-bg-inner: url('../app/assets/skyBlue/header/header_bg.png');
            --sidebar-bg-active: url('../app/assets/skyBlue/Sidebar/btn-vipactive.png');
            --setting-bg-header: url('../app/assets/skyBlue/other_modal/OtherModalHeader.png');
            --close-modal-button: url('../app/assets/skyBlue/other_modal/othermodal_xbtn.png');
            --setting-pop-logo: url('../app/assets/skyBlue/other_modal/poplogo.png');
            --accordion-body-color: #888888;
        }

        &[data-theme='darkBlue'] {
            --common-text-active: linear-gradient(180deg, #29468d 0%, #162752 100%);
            --common-text-unactive: linear-gradient(180deg, #ffffff 0%, #8aeaff 100%);
            --vip-header: #8aeaff;
            --balance-outline-header: linear-gradient(180deg, #ffffff, #8aeaff);
            --balance-bg-header: #102c54;
            --settings-header: linear-gradient(180deg, #ffffff 0%, #e1f5fe 100%);
            --setting-password-label: #ffffff;
            --setting-bg-content: linear-gradient(90deg, #4d6296 0%, #8498c9 100%);
            --setting-music-range: #00000080;
            --setting-bg-basic-info: #29468d;
            --setting-bg-basic-text: #fff;
            --setting-header-title-text: #1d2857;
            --setting-profile-border: #8aeaff;
            --setting-bg-input: #00000080;
            --vip-outline-bar: linear-gradient(180deg, #fff, #8aeaff);
            --vip-progress-bar: linear-gradient(180deg, #fff, #8aeaff);
            --vip-header-title: linear-gradient(#29468d, #162752);
            --vip-right-bar: #d7e8f0;
            --profile-bg: linear-gradient(300deg, #eff8ff, #d3edfe) border-box;
            --footer-text-list: linear-gradient(180deg, #ffffff 0%, #e1f5fe 100%);
            --footer-dialog-text: linear-gradient(180deg, #fff, #e1f5fe);
            --home-bg-sidebar: linear-gradient(270deg, #29334b 0%, #4d6296 100%);
            --home-text-unactive: linear-gradient(180deg, #ffffff 0%, #8aeaff 100%);
            --home-text-active: linear-gradient(180deg, #29468d 0%, #162752 100%);
            --home-marquee: #29468d;
            --home-placeholder-search: #29468d;
            --record-activeNav-bg: linear-gradient(to top, #162752, #29468d);
            --record-activeNav-after-bg: linear-gradient(to bottom, #fff, #8aeaff);
            --record-dropdownLabel-color: #29468d;
            --record-roundedTabs-bg: #00000080;
            --record-roundedTabs-color: #ffffff;
            --sidebar-outline-stroke: linear-gradient(to left, #3beaff00, #5dfeff, #3beaff00);
            --promotion-bg-title: #29468d;
            --promotion-text-input: #29468d;
            --promotion-bg-input: #fff;
            --security-header-border: #4d6296;
            --security-header-bg: #4d6296;
            --security-title-bg: linear-gradient(to top, #162752, #29468d);
            --vip-balance-bg: linear-gradient(to right, #4d6296, #8498c9);
            --vip-balance-text: #fff;
            --vip-balance-amount: #fff;
            --vip-balance-btn: linear-gradient(#29468d, #162752);
            --vip-btn-bg: linear-gradient(#29468d, #162752);
            --vip-btn-text: #fff;
            --nodata-bg: #4d6296;
            --sidebar-modal-bg: linear-gradient(270deg, #29334b 0%, #4d6296 100%);
            --sidebar-modal-border: #8aeaff;
            --promotion-lower-bg: #fff;
            --promotion-lower-title: -webkit-linear-gradient(#29468d, #162752);
            --promotion-lower-sub: #4d6296;
            --transfer-amount: linear-gradient(180deg, #ffffff 0%, #fffae9 0.01%, #face13 27.6%);
            --sidebar-modal-divider: url('../app/assets/darkBlue/Sidebar/divider.png');
            --sidebar-modal-active-bg: url('../app/assets/darkBlue/Sidebar/btn-vipactive.png');
            --header-bg-img: url('../app/assets/darkBlue/header/home_header.png');
            --footer-bg-img: url('../app/assets/darkBlue/footer/bottom-bg.webp');
            --footer-dialog-box: url('../app/assets/darkBlue/footer/MoreBalloon.png');
            --home-bg-title-sidebar: url('../app/assets/darkBlue/main/DottedSideBar.png');
            --home-title-sidebar: url('../app/assets/darkBlue/main/sidebar_title.png');
            --home-bg-active: url('../app/assets/darkBlue/main/sb_active_item.png');
            --home-bg-search: url('../app/assets/darkBlue/main/search-bg.png');
            --header-bg-inner: url('../app/assets/darkBlue/header/header_bg.png');
            --sidebar-bg-active: url('../app/assets/darkBlue/Sidebar/btn-vipactive.png');
            --setting-bg-header: url('../app/assets/darkBlue/other_modal/OtherModalHeader.png');
            --close-modal-button: url('../app/assets/darkBlue/other_modal/othermodal_xbtn.png');
            --setting-pop-logo: url('../app/assets/darkBlue/other_modal/poplogo.png');
        }

        &[data-theme='redGold'] {
            --balance-bg-header: #480909;
            --vip-bg-bar: #331b20;
            --settings-header: linear-gradient(180deg, #ffffff 0%, #f9d57a 100%);
            --setting-password-label: #080000;
            --setting-bg-content: linear-gradient(90deg, #fdf8f8 0%, #f7eeee 100%);
            --setting-music-range: #00000080;
            --setting-bg-basic-info: #af0100;
            --setting-bg-basic-text: #fff;
            --setting-profile-border: #dec18c;
            --setting-bg-input: linear-gradient(90deg, #eed1d1 0%, #dec3c3 100%);
            --home-bg-sidebar: linear-gradient(270deg, #771818 0%, #af0100 100%);
            --home-placeholder-search: #fff;
            --record-dropdownLabel-color: #080000;
            --record-roundedTabs-bg: #ffffff;
            --record-roundedTabs-color: #080000;
            --promotion-bg-title: linear-gradient(to right, #af0100, #ff6b00);
            --promotion-text-input: #080000;
            --promotion-bg-input: linear-gradient(to right, #f1dbdb, #f4e8e8);
            --security-header-bg: linear-gradient(to right, #af0100, #ff6b00);
            --security-title-bg: linear-gradient(to top, #47100f, #6a1211);
            --vip-balance-bg: #fff;
            --vip-balance-text: #080000;
            --vip-balance-amount: #00ff00;
            --vip-header-title: linear-gradient(#47100f, #6a1211);
            --vip-right-bar: #fff;
            --nodata-bg: #af0100;
            --sidebar-modal-bg: linear-gradient(270deg, #771818 0%, #af0100 100%);
            --promotion-lower-bg: #f1dbdb;
            --promotion-lower-title: #af0100;
            --promotion-lower-sub: #080000;
            --transfer-amount: linear-gradient(180deg, #ffffff 0%, #fffae9 0.01%, #face13 27.6%);
            --sidebar-modal-divider: url('../app/assets/redGold/Sidebar/divider.png');
            --sidebar-modal-active-bg: url('../app/assets/redGold/Sidebar/btn-vipactive.png');
            --header-bg-img: url('../app/assets/redGold/header/home_header.png');
            --footer-bg-img: url('../app/assets/redGold/footer/bottom-bg.webp');
            --footer-dialog-box: url('../app/assets/redGold/footer/MoreBalloon.png');
            --home-bg-title-sidebar: url('../app/assets/redGold/main/DottedSideBar.png');
            --home-title-sidebar: url('../app/assets/redGold/main/sidebar_title.png');
            --home-bg-active: url('../app/assets/redGold/main/sb_active_item.png');
            --home-bg-search: url('../app/assets/redGold/main/search-bg.png');
            --header-bg-inner: url('../app/assets/redGold/header/header_bg.png');
            --sidebar-bg-active: url('../app/assets/redGold/Sidebar/btn-vipactive.png');
            --setting-bg-header: url('../app/assets/redGold/other_modal/OtherModalHeader.png');
            --close-modal-button: url('../app/assets/redGold/other_modal/othermodal_xbtn.png');
            --setting-pop-logo: url('../app/assets/redGold/other_modal/poplogo.png');
            --accordion-body-color: #080000;
        }

        &[data-theme='whiteGold'] {
            --common-text-active: #461d09;
            --common-text-unactive: #435689;
            --common-header: #435689;
            --vip-header: #fea800;
            --balance-bg-header: #737378;
            --settings-header: #435689;
            --setting-password-label: #435689;
            --setting-bg-content: #dadce7;
            --setting-music-range: #00000080;
            --setting-bg-basic-info: #e9cb7b;
            --setting-bg-basic-text: #461d09;
            --setting-profile-border: #feeba6;
            --setting-bg-input: #ffffff;
            --vip-outline-bar: #737378;
            --vip-bg-bar: #737378;
            --vip-header-title: linear-gradient(#435689, #435689);
            --vip-right-bar: #000000;
            --profile-bg: linear-gradient(150deg, #fff2c5, #d8aa42) border-box;
            --footer-text-list: #435689;
            --footer-dialog-text: #435689;
            --home-bg-sidebar: linear-gradient(270deg, #fcfbfe 0%, #ccd1e6 76.47%);
            --home-text-unactive: #435689;
            --home-text-active: #461d09;
            --home-placeholder-search: #ccd1e6cc;
            --record-dropdownLabel-color: #435689;
            --record-roundedTabs-bg: #ffffff;
            --record-roundedTabs-color: #435689;
            --promotion-text-title: #461d09;
            --promotion-bg-title: #e9cb7b;
            --promotion-text-input: #000;
            --promotion-bg-input: #dadce7;
            --security-header-border: #e9cb7b;
            --security-header-bg: #f9f9f9;
            --security-title-text: #461d09;
            --security-title-bg: linear-gradient(to right, #e9cb7b, #e9cb7b);
            --vip-balance-bg: #e9cb7b;
            --vip-balance-text: #461d09;
            --vip-balance-amount: #06b100;
            --nodata-bg: #bcbcbc;
            --sidebar-modal-bg: #dadce7;
            --promotion-lower-bg: #dadce7;
            --promotion-lower-title: #435689;
            --promotion-lower-sub: #435689;
            --transfer-text: #461d09;
            --transfer-amount: #461d09;
            --sidebar-modal-divider: url('../app/assets/whiteGold/Sidebar/divider.png');
            --sidebar-modal-active-bg: url('../app/assets/whiteGold/Sidebar/btn-vipactive.png');
            --header-bg-img: url('../app/assets/whiteGold/header/home_header.png');
            --footer-bg-img: url('../app/assets/whiteGold/footer/bottom-bg.webp');
            --footer-dialog-box: url('../app/assets/whiteGold/footer/MoreBalloon.png');
            --home-bg-title-sidebar: url('../app/assets/whiteGold/main/DottedSideBar.png');
            --home-title-sidebar: url('../app/assets/whiteGold/main/sidebar_title.png');
            --home-bg-active: url('../app/assets/whiteGold/main/sb_active_item.png');
            --home-bg-search: url('../app/assets/whiteGold/main/search-bg.png');
            --header-bg-inner: url('../app/assets/whiteGold/header/header_bg.png');
            --sidebar-bg-active: url('../app/assets/whiteGold/Sidebar/btn-vipactive.png');
            --setting-bg-header: url('../app/assets/whiteGold/other_modal/OtherModalHeader.png');
            --close-modal-button: url('../app/assets/whiteGold/other_modal/othermodal_xbtn.png');
            --setting-pop-logo: url('../app/assets/whiteGold/other_modal/poplogo.png');
            --accordion-body-color: #C5915A;
        }
    }
}

@font-face {
    font-family: 'Inter';
    src: url('../app/assets/commons/fonts/Inter/Inter-Regular.ttf');
    font-weight: 700;
    font-style: normal;
}