// Baccarat

.issueHeader {
  background: var(--table-header);
  background-clip: text;
  width: 0.8rem;
  margin-left: 0.8rem;
}

.playerContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .playerCards {
    display: flex;
    width: 1.2rem;
    margin-right: 0.1rem;
    height: 0.5rem;
    padding-top: 0.12rem;
    padding-left: 0.12rem;

    .pText {
      display: flex;
      margin-top: 0.02rem;
      background: #007eff;
      width: 0.2rem;
      height: 0.2rem;
      border-radius: 0.2rem;
      font-weight: 900;
      font-size: 0.08rem;
      align-items: center;
      justify-content: center;
      padding-bottom: 0.01rem;

      :nth-child(2) {
        background: #ff3b3b;
      }

      .bcards .pcards {
        color: white;
      }
    }
    .cardsContainer {
      display: inline-block;

      .cardsData {
        width: 0.15rem;
        margin-left: 0.05rem;
        margin-top: 0.02rem;
        display: flex;
      }
    }
  }
}

.pcards {
  background: #fff;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.bcards {
  background: #fff;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.diceImageContainer {
  width: 0.15rem;
  margin-left: 0.02rem;
  padding-top: 0.02rem;
}
