.cateListWrapper {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 73.5%;
  height: 0.35rem;
  padding-left: 0.1rem;
  overflow: auto;

  :global {
    .swiper {
      width: 100%;
      height: 100%;
      margin-left: auto;
      margin-right: auto;
      overflow: auto;
    }
    .swiper-wrapper {
      gap: 0.2rem;
      display: flex;
      align-items: center;
      position: relative;
      left: 0.1rem;
    }
    .swiper-slide {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 0.35rem;
      width: 0.9rem !important;
    }
  }

  .lists {
    // width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 0.1rem;

    .iconHolder {
      cursor: pointer;
      position: relative;
      height: 100%;
      width: 0.9rem;
      background-image: url("../../../../../../assets/blackGold/main/gametopNorm.png");
      background-position: center center;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.02rem;
      img {
        position: relative;
        top: 0;
        height: 0.22rem;
        width: 0.22rem;
        margin-left: 0.05rem;
      }
      span {
        margin-right: 0.05rem;
        font-size: 0.12rem;
        // -webkit-text-fill-color: transparent;
        // background: -webkit-linear-gradient(#fff, #f9d57a);
        -webkit-background-clip: text;
        // font-weight: 700;
        color: #f9d57a;
        font-weight: 300;
        white-space: nowrap;
      }
      &[data-theme="yellowWhite"]{
        background: #C5915A;
      }
      &[data-theme="greenYellow"]{
        background: rgba(255,255,255, 0.4);
      }
      &[data-theme="skyBlue"]{
        background: rgba(0,0,0, 0.3);
        span{
          color: #FFF6C2;
        }
      }
      &[data-theme="darkBlue"]{
        background: rgba(255,255,255, 0.6);
        // color: pink;
        span{
          color: #162752;
        }
      }
      &[data-theme="whiteGold"]{
        background: rgba(255,255,255, 0.4);
        span{
          color: #ffffff;
        }
      }
    }

    .iconHolderActive {
      background-image: url("../../../../../../assets/blackGold/main/gametopAct.png");
      &[data-theme="yellowWhite"]{
        background-image: url("../../../../../../assets/yellowWhite/main/gametopAct.png");
        background-size: 100%;
      }
      &[data-theme="greenYellow"]{
        background-image: url("../../../../../../assets/greenYellow/main/gametopAct.png");
        background-size: 100%;
      }
      &[data-theme="skyBlue"]{
        background-image: url("../../../../../../assets/skyBlue/main/gametopAct.png");
      }
      &[data-theme="darkBlue"]{
        background-image: url("../../../../../../assets/darkBlue/main/gametopAct.png");
        background-size: 100%;
        span{
          color: #ffffff;
        }
      }
      &[data-theme="whiteGold"]{
        background-image: url("../../../../../../assets/whiteGold/main/gametopAct.png");
        background-size: 100%;
        span{
          color: #461D09;
        }
      }
    }      
  }
}
