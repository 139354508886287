.container {
  display: flex;
  // width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  position: relative;
  .collectionRecord{
    height: 100%;
    width: 100%;
  }
}

.collectionRecord_header {
  width: 6.2rem;
  margin: 0.05rem 0 0 0.15rem;
  height: 0.2rem;
  font-size: 0.15rem;
  background: linear-gradient(to bottom, #2c2c2c, #1a1a1a);
  border-radius: 0.04rem;
  color: #cdcaca;
  padding: 0.025rem 0 0.025rem 0;

  ul {
    display: flex;
    list-style: none;

    li {
      text-align: center;
      width: 3rem;
    }
  }
}
.pullToRefresh {
  :global {
    .ptr__pull-down > div {
      p {
        font-size: 0.15rem;
        color: #f6da85;
        text-align: center !important;
      }
    }
  }
}

.collectionRecordTableContainer {
  width: 100%;
  height: 100%;
  position: relative;
  .noDataWrapper {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    :global {
      .noDataContainer {
        height: 100%;
      }
    }
  }
  &[data-theme="yellowWhite"] {
    :global {
      table {
        thead {
          tr {
            th {
              color: #FFC400 !important;
            }
          }
        }
      }
    }
  }
}

// @media screen and (min-height: 240px) {
//   .container {
//     height: 20%;
//   }
// }
// @media screen and (min-height: 300px) {
//   .container {
//     height: 47%;
//   }
// }
// @media screen and (min-height: 360px) {
//   .container {
//     height: 47%;
//   }
// }
// @media screen and (min-height: 400px) {
//   .container {
//     height: 65%;
//   }
// }
// @media screen and (min-height: 450px) {
//   .container {
//     height: 70%;
//   }
// }
// @media screen and (min-height: 500px) {
//   .myPromotion {
//     height: 100%;
//   }
// }
