.coinpurse_wrapper {
  font-size: 0.1rem;
  display: flex;
  align-items: center;
  height: 47%;
  width: 65%;

  .coinInput2 {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 0.22rem;
    position: relative;
    overflow: hidden;
    border-radius: 15rem;
    background: var(--balance-outline-header);

    input {
      position: relative;
      line-height: 0.20rem;
      font-size: 0.15rem;
      background: var(--balance-bg-header);
      border: none;
      color: var(--balance-header);
      -webkit-opacity: 1;
      height: 0.20rem;
      z-index: 1;
      border-radius: 15rem;
      right: 0.0092rem;
    }
  }
  .img2 {
    position: absolute;
    left: -0.1rem;
    top: -0.04rem;
    z-index: 2;
    width: 0.4rem;
  }
}

.shuffles {
  display: flex;
  justify-content: center;
  width: 20%;
  margin-left: -0.05rem;
  img {
    width: 50%;
    height: 50%;
  }
}