.shareModalContainer {
  // width: 2rem;
  width: 3rem;
  // height: 1.5rem;
  height: 1.7rem;
  background: #1f1e1b;
  border: 0.0004rem solid #8d7c4f;
  border-radius: 0.08rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -0.5rem;
  flex-direction: column;
  gap: 0.1rem;
  .Button{
    width: 100%;
    justify-content: center;
    display: flex;
    height: 10%;
    :global{
      .buttonDesignTwo{
        margin-top: 0;
        padding: 0;
        display: flex;
        align-items: center;
        background-size: 100%;
        height: 100%;
        span{
          position: relative;
          top: 0;
        }
      }
    }
  }
}

.platformContainer {
  // width: 2.5rem;
  width: 79%;
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.07rem;
  &::-webkit-scrollbar {
    display: none;
  }
}

.copyOption {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 0.2rem;
  gap: 0.08rem;
  // margin-top: 0.15rem;
  width: 79%;
  justify-content: space-between;
}

.shareUrlContainer {
  background-color: #2c2c2c;
  // width: 1.4rem;
  width: 2rem;
  height: 0.2rem;
  display: flex;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
  border-radius: 0.04rem;
  padding: 0.05rem;
  align-items: center;
  justify-content: center;
  span {
    // width: 1.4rem;
    height: 0.12rem;
    display: flex;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: center;
  }
}
.copyIcon {
  display: flex;
  height: 0.2rem;
  align-items: center;
  justify-content: center;
  div {
    display: flex;
    align-items: center;
    justify-content: center;
    display: inline-block;
    background-color: #2c2c2c;
    height: 0.2rem;
    width: 0.2rem;
    border-radius: 0.2rem;
    padding: 0.05rem;
    img {
      width: 0.1rem;
    }
  }
}
