@import '/src/styles/_helpers.scss';

.sidebarContainer {
  @include flex-xy(flex-start, center);
  flex-direction: column;
  width: 1.5rem;
  height: 2.49rem;
  top: -0.01rem;
  position: relative;
  background: var(--sidebar-modal-bg);
  border: 0.01rem solid var(--sidebar-modal-border);

  ul {
    position: relative;
    @include flex-xy(flex-start, center);
    flex-direction: column;
    list-style: none;
    font-size: 0.13rem;
    font-weight: 900;
    top: 0.04rem;
    width: 100%;
    height: 2.45rem;
    display: flex;
    overflow: hidden;
    overflow-y: auto;

    li {
      position: relative;
      @include flex-xy(space-between, center);
      flex-direction: column;
      width: 100%;
      padding: 0.05rem 0;
      white-space: nowrap;

      span {
        display: flex;
        align-items: center;
        background: var(--home-text-unactive);
        background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 0.15rem;
        height: 100%;
        font-weight: 400;
      }

      &.active {
        background: var(--sidebar-modal-active-bg) center / 100% 100% no-repeat !important;
        color: white;
        font-weight: 700;

        span {
          background: var(--home-text-active);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }

      &::after {
        content: '';
        display: inline-block;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        background: var(--sidebar-modal-divider);
        background-repeat: no-repeat;
        background-size: contain;
      }
    }
  }

  .homeNoticeItem {
    position: relative;

    s {
      position: absolute;
      left: 0.05rem;
      top: 0.04rem;
      width: 0.07rem;
      height: 0.07rem;
      border-radius: 0.07rem;
      background-color: red;
    }
  }
}
