.csPop {
  font-size: 0.15rem;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: 0rem 0.08rem;

  &__headerText {
    width: 3.35rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    span:first-child {
      font-family: "Inter";
      font-size: 0.2rem;
      font-weight: 400;
      text-align: left;
      background: linear-gradient(180deg, #ffffff 0%, #ffffdc 100%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    span:nth-child(2) {
      font-family: Microsoft YaHei UI;
      font-size: 0.15rem;
      font-weight: 400;
      text-align: left;
      color: #ffffff;
    }
  }

  .csLinksWrapper {
    height: 100%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;

    li {
      margin-top: 0.05rem;
      background-color: #222222;
      width: 100%;
      height: 0.73rem;
      border-radius: 0.04rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.064rem 0.1rem;
    }
  }

  .leftContent {
    height: 100%;
    max-width: 4.5rem;
    display: flex;

    img {
      height: 100%;
      width: auto;
      margin-right: 0.1rem;
    }

    div {
      padding: 0.05rem 0;
      display: flex;
      flex-direction: column;

      span:first-child {
        color: #ffffff;
        font-family: Inter;
        font-size: 0.18rem;
        font-weight: 400;
        text-align: left;
      }
      span:last-child {
        color: #f0cb5a;
        font-family: Inter;
        font-size: 0.18rem;
        font-weight: 400;
        text-align: left;
      }
    }
  }

  .rightContent {
    width: 1.7rem;
    margin-right: 0.03rem;
    display: flex;
    justify-content: space-between;

    div {
      width: 0.57rem;
      height: 0.28rem;
      border-radius: 0.23rem;
      border: 0.01rem solid #72728f;
      display: flex;
      justify-content: center;
      align-items: center;
      span {
        color: #757575;
      }
    }

    a {
      width: 1.06rem;
      height: 0.28rem;
      border-radius: 0.23rem;
      display: flex;
      justify-content: center;
      align-items: center;
      background: linear-gradient(180deg, #ffe800 0%, #e38c00 100%);
      box-shadow: 0 0.005rem 0.005rem 0 #ffffff;

      span {
        color: #651d00;
      }
    }
  }

  &[data-theme="brownGold"] {
    .csPop__headerText {
      span {
        background: -webkit-linear-gradient(#a11300, #a11300);
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    .csLinksWrapper {
      ul {
        li {
          background-color: #c4a994;
          color: #fff;

          .leftContent {
            div {
              span:last-child {
                color: #888888;
              }
            }
          }

          .rightContent {
            div {
              border-color: #fff;
              span {
                color: #fff;
              }
            }
          }
        }
      }
    }
  }
  &[data-theme="yellowWhite"] {
    .csPop__headerText {
      span {
        background: -webkit-linear-gradient(#a11300, #a11300);
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    .csLinksWrapper {
      ul {
        li {
          background-color: #ebedf1;
          color: #c5915a;

          .leftContent {
            div {
              span:first-child {
                color: #bea288;
              }
              span:last-child {
                color: #bea288;
              }
            }
          }

          .rightContent {
            div {
              border-color: #bea288;
              span {
                color: #bea288;
              }
            }
          }
        }
      }
    }
  }
  &[data-theme="greenYellow"] {
    .csPop__headerText {
      span {
        background: -webkit-linear-gradient(#a11300, #a11300);
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
    .csLinksWrapper {
      ul {
        li {
          background-color: #0e9473;
          color: #fff;

          .leftContent {
            div {
              span:first-child {
                color: #fff;
              }
              span:last-child {
                color: #fff;
              }
            }
          }
          .rightContent {
            div {
              border-color: #fff;
              span {
                color: #fff;
              }
            }
          }
        }
      }
    }
  }
  &[data-theme="skyBlue"] {
    .csPop__headerText {
      span {
        background: -webkit-linear-gradient(#511a00, #511a00);
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    .csLinksWrapper {
      ul {
        li {
          background-color: #0ca0e9;
          color: #fff;

          .leftContent {
            div {
              span:first-child {
                color: #ffffff;
              }
              span:last-child {
                color: #ffffff;
              }
            }
          }

          .rightContent {
            div {
              border-color: #fff;
              span {
                color: #fff;
              }
            }
          }
        }
      }
    }
  }
  &[data-theme="darkBlue"] {
    .csPop__headerText {
      span {
        background: -webkit-linear-gradient(
          #162752,
          #162752,
          #162752,
          #29468d,
          #29468d
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
      }
    }

    .csLinksWrapper {
      ul {
        li {
          background: linear-gradient(90deg, #4d6296 0%, #8498c9 100%);
          color: #fff;

          .leftContent {
            div {
              span:first-child {
                color: #ffffff;
              }
              span:last-child {
                color: #ffffff;
              }
            }
          }
          .rightContent {
            div {
              border-color: #fff;
              span {
                color: #fff;
              }
            }
          }
        }
      }
    }
  }
  &[data-theme="redGold"] {
    .csPop__headerText {
      span {
        background: -webkit-linear-gradient(#511a00, #511a00);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
      }
    }

    .csLinksWrapper {
      ul {
        li {
          background: linear-gradient(90deg, #af0100 0%, #ff6b00 100%);
          color: #fff;

          .leftContent {
            div {
              span:first-child {
                color: #ffffff;
              }
              span:last-child {
                color: #ffffff;
              }
            }
          }

          .rightContent {
            div {
              border-color: #fff;
              span {
                color: #fff;
              }
            }
          }
        }
      }
    }
  }
  &[data-theme="whiteGold"] {
    .csPop__headerText {
      span {
        background: -webkit-linear-gradient(#511a00, #511a00);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
      }
    }

    .csLinksWrapper {
      ul {
        li {
          background: linear-gradient(90deg, #f4e8e8 0%, #f1dbdb 100%);
          color: #080000;

          .leftContent {
            div {
              span:first-child {
                color: #435689;
              }
              span:last-child {
                color: #435689;
              }
            }
          }

          .rightContent {
            div {
              border-color: #435689;
              span {
                color: #435689;
              }
            }
          }
        }
      }
    }
  }
}

.pullToRefresh {
  :global {
    .ptr__pull-down > div {
      p {
        font-size: 0.15rem;
        color: #f6da85;
        text-align: center !important;
      }
    }
  }
}
