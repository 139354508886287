table {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;

     &[data-theme="greenYellow"] {
      tbody tr:nth-child(odd) {
        background-color: #8abeb1;
  
        color: #511a00;
      }
      tbody tr:nth-child(even) {
        background-color: #F2F2F2 ;
  
        color: #511a00;
      }
  
      table thead {
        position: sticky;
        top: 0;
        border-radius: 2rem;
        background: #0e9473;
      }
    }

    tbody tr:nth-child(odd) {
      background-color: #222222;
      border: none;
      text-align: center;
      color: #fff6c2;
      font-size: 0.12rem;
      height: 0.25rem;
    }
    tbody tr:nth-child(even) {
      background-color: #2c2c2c;
      border: none;
      text-align: center;
      color: #fff6c2;
      font-size: 0.12rem;
      height: 0.25rem;
    }
  
    tbody tr span {
      background: -webkit-linear-gradient(#ffffff, #fff6c2);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  
    /* table tr:hover {background-color: #ddd;} */
    tbody {
      width: 100%;
      height: 100%;
    }
  
    table th {
      height: 0.3rem;
      text-align: center;
      /* border:none !important; */
      color: #cdcaca;
    }
  
    thead {
      position: sticky;
      top: 0;
      border-radius: 2rem;
      background: linear-gradient(to bottom, #2c2c2c, #1a1a1a);
    }
  
    .prioWhite {
      background: -webkit-linear-gradient(#ffffff, #ffffff);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  