table {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  // height: 90%;

  &[data-theme="blackGold"] {
  }

  &[data-theme="brownGold"] {
    tbody tr:nth-child(odd) {
      background-color: #f7ede7;

      color: #511a00;
    }

    tbody tr:nth-child(even) {
      background-color: #ffffff;

      color: #511a00;
    }

    thead {
      position: sticky;
      top: 0;
      height: 0.3rem;
      border-radius: 2rem;
      background: #c4a994;
    }

    tbody tr span {
      background: -webkit-linear-gradient(#000000, #000000);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  &[data-theme="yellowWhite"] {
    tbody tr:nth-child(odd) {
      background: linear-gradient(to left, #ebedf1, #ebedf1);

      color: #511a00;
    }

    tbody tr:nth-child(even) {
      background: linear-gradient(to left, #fafafa, #fafafa);

      color: #511a00;
    }

    thead {
      position: sticky;
      top: 0;
      height: 0.3rem;
      border-radius: 2rem;
      background: linear-gradient(to top, #0a0a0a, #0a0a0a);
      color: #fff;
    }

    tbody tr span {
      background: -webkit-linear-gradient(#a11300, #a11300);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    tr {
      td {
        span {
          background: -webkit-linear-gradient(#c5915a, #c5915a) !important;
          -webkit-background-clip: text !important;
          -webkit-text-fill-color: transparent !important;
        }
      }
    }
  }

  &[data-theme="greenYellow"] {
    tbody tr:nth-child(odd) {
      background-color: #8abeb1;

      color: #511a00;
    }

    tbody tr:nth-child(even) {
      background-color: #ffffff;

      color: #511a00;
    }

    thead {
      position: sticky;
      top: 0;
      height: 0.3rem;
      border-radius: 2rem;
      background: #0e9473;
    }

    tbody tr span {
      background: -webkit-linear-gradient(#511a00, #511a00);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  &[data-theme="skyBlue"] {
    tbody tr:nth-child(odd) {
      background: linear-gradient(to left, #f4f6f9, #f4f6f9);

      color: #511a00;
    }

    tbody tr:nth-child(even) {
      background: linear-gradient(to left, #ffffff, #ffffff);

      color: #511a00;
    }

    thead {
      position: sticky;
      top: 0;
      height: 0.3rem;
      border-radius: 2rem;
      background: linear-gradient(to top, #0ca0e9, #0ca0e9);
      color: #fff;
    }

    tbody tr span {
      background: -webkit-linear-gradient(#000000, #000000);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  &[data-theme="darkBlue"] {
    tbody tr:nth-child(odd) {
      background: linear-gradient(to left, #8498c9, #4D6296);

      color: #511a00;
    }

    tbody tr:nth-child(even) {
      background: linear-gradient(to left, #29334B 0, #4D6296);

      color: #511a00;
    }

    thead {
      position: sticky;
      top: 0;
      height: 0.3rem;
      border-radius: 2rem;
      background: linear-gradient(to top, #162752, #29468d);
    }

    tbody tr span {
      background: -webkit-linear-gradient(#fff, #fff);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    .prioBlue {
      background: -webkit-linear-gradient(#29468d, #29468d);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  &[data-theme="redGold"] {
    tbody tr:nth-child(odd) {
      background: linear-gradient(to top, #f6eeee, #fdf8f8);

      color: #511a00;
    }

    tbody tr:nth-child(even) {
      background: linear-gradient(to top, #f8e7e7, #f1dfdf);

      color: #511a00;
    }

    thead {
      position: sticky;
      top: 0;
      height: 0.3rem;
      border-radius: 2rem;
      background: linear-gradient(to top, #af0100, #ff6b00);
      color: #fff;
    }

    tbody tr span {
      background: -webkit-linear-gradient(#080000, #080000);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  &[data-theme="whiteGold"] {
    tbody tr:nth-child(odd) {
      background: linear-gradient(to left, #f9f9f9, #f9f9f9);

      color: #511a00;
    }

    tbody tr:nth-child(even) {
      background: linear-gradient(to left, #ffeec3, #ffeec3);

      color: #511a00;
    }

    thead {
      position: sticky;
      top: 0;
      height: 0.3rem;
      border-radius: 2rem;
      background: linear-gradient(to top, #e9cb7b, #e9cb7b);
      color: #461d09;
    }

    tbody tr span {
      background: -webkit-linear-gradient(#435689, #435689);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  tbody tr:nth-child(odd) {
    background-color: #222222;
    border: none;
    text-align: center;
    color: #fff6c2;
    font-size: 0.12rem;
    min-height: 0.1rem !important;
    max-height: 0.1rem !important;
  }

  tbody tr:nth-child(even) {
    background-color: #2c2c2c;
    border: none;
    text-align: center;
    color: #fff6c2;
    font-size: 0.12rem;
    min-height: 0.1rem !important;
    max-height: 0.1rem !important;
  }

  tbody tr span {
    background: -webkit-linear-gradient(#ffffff, #fff6c2);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 0.12rem;
  }

  /* table tr:hover {background-color: #ddd;} */
  tbody {
    width: 100%;
    height: 90%;
    tr {
      height: 0.28rem !important;
      td {
        span {
        }
      }
    }
  }

  table th {
    height: 0.28rem !important;
    text-align: center;
    /* border:none !important; */
    color: #cdcaca;
  }

  thead {
    position: sticky;
    top: 0;
    border-radius: 2rem;
    height: 0.28rem !important;

    background: linear-gradient(to bottom, #2c2c2c, #1a1a1a);
  }

  .prioWhite {
    background: -webkit-linear-gradient(#ffffff, #ffffff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .prioBrown {
    background: -webkit-linear-gradient(#651d00, #651d00) !important;
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent !important;
  }
}

td:last-child,
th:last-child {
  border: none !important; /* no margin for the last cell */
}

.navigateHome {
  display: flex;
  align-items: center;
  justify-content: center;

  .button {
    font-size: 0.1rem;
    font-weight: 700;
    padding: 0.05rem;
    border-radius: 0.2rem;
  }
}
