.promoteModal_body {
  width: 4rem;
  height: 20%;
  background: #1f1e1b;
  border: #8d7c4f 0.004rem solid;
  border-radius: 0.1rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  > center {
    width: 100%;
    padding: 0.2rem;
  }
}

.ThisModalMainContainer_promote {
  position: absolute;
  z-index: 100;
  height: 100%;
  left: 0;
  top: 0;
  width: 100%;
}
