.container {
  display: flex;
  width: 100%;
  overflow-y: scroll;
  flex-direction: column;
  // align-items: center;
  justify-content: center;
  font-weight: 500;
  color: #fff6c2;
  font-size: 0.1rem;
  letter-spacing: -0.01rem;
  gap: 0.1rem;
  &[data-theme="brownGold"] {
    .myPromotion_inputUpperBox {
      background-color: #fff;
    }
    .myPromotion_header {
      border-bottom: #c4a994 0.00001rem solid;

      .myPromotion_titleHolder {
        color: #fff;
        background: linear-gradient(to right, #c4a994, #c4a994);
      }

      .myPromotion_goNext {
        display: flex;

        span {
          background: -webkit-linear-gradient(#bebebe, #bebebe);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
    }

    color: #bebebe;

    .myPromotion_inputLowerBox {
      background: #ffffff;
    }

    .lowerDetails_oneBox {
      background: #3b8675;

      .lowerDetails_yellowText {
        background: -webkit-linear-gradient(#3b8675, #bf8734);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        // background:yellow;
      }
    }

    .myPromotion_lowerAdditionals {
      button {
        background: linear-gradient(to bottom, #ffe800, #e39300);
      }
    }

    .lowerAdditionals_urlHolder {
      background: #ededed;

      color: #bebebe;
    }

    .linkHolder_linkbox {
      color: #511a00;

      background: #ededed;
      .linkbox_link {
        background: linear-gradient(to bottom, #ffe800, #e38c00);
      }
    }
  }
  &[data-theme="yellowWhite"] {
    .myPromotion_inputUpperBox {
      background-color: #fff;
    }
    .myPromotion_header {
      border-bottom: #ffc400 0.00001rem solid;

      .myPromotion_titleHolder {
        color: #fff;
        background: linear-gradient(to right, #ffc400, #ffc400);
      }

      .myPromotion_goNext {
        display: flex;

        span {
          background: -webkit-linear-gradient(#a11300, #a11300);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
    }

    color: #a11300;
    .myPromotion_inputLowerBox {
      background: #ffffff;
    }

    .lowerDetails_oneBox {
      background: #3b8675;

      .lowerDetails_yellowText {
        background: -webkit-linear-gradient(#3b8675, #bf8734);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        // background:yellow;
      }
    }

    .myPromotion_lowerAdditionals {
      button {
        background: linear-gradient(to bottom, #ffe800, #e39300);
      }
    }

    .lowerAdditionals_urlHolder {
      background: #1a1a1a;

      color: #cdcaca;
    }

    .linkHolder_linkbox {
      color: #511a00;

      background: #1a1a1a;
      .linkbox_link {
        background: linear-gradient(to bottom, #ffe800, #e38c00);
      }
    }
  }
  &[data-theme="greenYellow"] {
    .myPromotion_inputUpperBox {
      background-color: #f2f2f2;
    }
    .myPromotion_header {
      border-bottom: #0e9473 0.00001rem solid;

      .myPromotion_titleHolder {
        background: linear-gradient(to right, #0e9473, #0e9473);
      }

      .myPromotion_goNext {
        display: flex;

        span {
          background: -webkit-linear-gradient(#511a00, #511a00);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
    }

    color: #facd12;

    .myPromotion_inputLowerBox {
      background: #f2f2f2;
    }

    .lowerDetails_oneBox {
      background: #3b8675;

      .lowerDetails_yellowText {
        background: -webkit-linear-gradient(#3b8675, #bf8734);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        // background:yellow;
      }
    }

    .myPromotion_lowerAdditionals {
      button {
        background: linear-gradient(to bottom, #ffe800, #e39300);
      }
    }

    .lowerAdditionals_urlHolder {
      background: #ffffff;

      color: #bebebe;
    }

    .linkHolder_linkbox {
      color: #511a00;

      background: #ffffff;
      .linkbox_link {
        background: linear-gradient(to bottom, #ffe800, #e38c00);
      }
    }
  }
  &[data-theme="skyBlue"] {
    .myPromotion_inputUpperBox {
      background-color: #f4f6f9;
    }
    .myPromotion_header {
      border-bottom: #0ca0e9 0.00001rem solid;

      .myPromotion_titleHolder {
        color: #fff;
        background: linear-gradient(to right, #0ca0e9, #0ca0e9);
      }

      .myPromotion_goNext {
        display: flex;

        span {
          background: -webkit-linear-gradient(#888888, #888888);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
    }

    color: #888888;

    .myPromotion_inputLowerBox {
      background: #f4f6f9;
    }

    .lowerDetails_oneBox {
      background: #fff;

      .lowerDetails_yellowText {
        background: -webkit-linear-gradient(#3b8675, #bf8734);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        // background:yellow;
      }
    }

    .myPromotion_lowerAdditionals {
      button {
        background: linear-gradient(to bottom, #ffe800, #e39300);
      }
    }

    .lowerAdditionals_urlHolder {
      background: #fff;

      color: #bebebe;
    }

    .linkHolder_linkbox {
      color: #511a00;

      background: #fff;
      .linkbox_link {
        background: linear-gradient(to bottom, #ffe800, #e38c00);
      }
    }
  }
  &[data-theme="darkBlue"] {
    .myPromotion_inputUpperBox {
      background: linear-gradient(to right, #4d6296, #8498c9);
      background-color: #fff;
    }
    .myPromotion_header {
      background: linear-gradient(to right, #4d6296, #8498c9);
      border-bottom: #29468d 0.00001rem solid;

      .myPromotion_titleHolder {
        color: #fff;
        background: linear-gradient(to top, #162752, #29468d);
      }

      .myPromotion_goNext {
        display: flex;

        span {
          background: -webkit-linear-gradient(#8aeaff, #ffffff);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
    }

    color: #8aeaff;

    .myPromotion_inputLowerBox {
      background: linear-gradient(to right, #4d6296, #8498c9);
    }

    .lowerDetails_oneBox {
      background: #3b8675;

      .lowerDetails_yellowText {
        background: -webkit-linear-gradient(#3b8675, #bf8734);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        // background:yellow;
      }
    }

    .myPromotion_lowerAdditionals {
      button {
        background: linear-gradient(to bottom, #fff, #8aeaff);
        color: #162752;
      }
    }

    .lowerAdditionals_urlHolder {
      background: #ffffff;

      color: #757575;
    }

    .linkHolder_linkbox {
      color: #511a00;

      background: #ffffff;
      .linkbox_link {
        color: #162752;
        background: linear-gradient(to bottom, #fff, #8aeaff);
      }
    }
  }
  &[data-theme="redGold"] {
    .myPromotion_inputUpperBox {
      background-color: #fdf8f8;
    }
    .myPromotion_header {
      background: linear-gradient(to right, #af0100, #ff6b00);
      border-bottom: #e9cb7b 0.00001rem solid;

      .myPromotion_titleHolder {
        color: #fbfbff;
        background: linear-gradient(to right, #47100f, #47100f);
      }

      .myPromotion_goNext {
        display: flex;

        span {
          background: -webkit-linear-gradient(#ffffff, #fff6c2);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
    }

    color: #fff6c2;

    .myPromotion_inputLowerBox {
      background: #ffffff;
    }

    .lowerDetails_oneBox {
      background: #3b8675;

      .lowerDetails_yellowText {
        background: -webkit-linear-gradient(#3b8675, #bf8734);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        // background:yellow;
      }
    }

    .myPromotion_lowerAdditionals {
      button {
        background: linear-gradient(to bottom, #ffe800, #e39300);
      }
    }

    .lowerAdditionals_urlHolder {
      background: #f4e8e8;

      color: #080000;
    }

    .linkHolder_linkbox {
      color: #511a00;

      background: #f4e8e8;
      .linkbox_link {
        background: linear-gradient(to bottom, #ffe800, #e38c00);
      }
    }
  }

  .myPromotion_inputUpperBox {
    width: 100%;
    height: 1.8rem;
    background-color: #222222;
    border-radius: 0.04rem;
    font-size: 0.15rem;
  }

  .myPromotion_header {
    width: 100%;

    height: 0.3rem;
    border: none;
    display: flex;
    border-bottom: #525252 0.00001rem solid;

    .myPromotion_titleHolder {
      width: 1rem;
      height: 0.3rem;
      padding: 0.04rem 0 0 0.2rem;
      background: linear-gradient(to right, #222222, #444444);
    }

    .myPromotion_goNext {
      // padding-top: 0.05rem;
      // margin-left: 3.85rem;

      span {
        background: -webkit-linear-gradient(#fff6c2, #ffffff);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }
  &[data-theme="whiteGold"] {
    .myPromotion_inputUpperBox {
      background-color: #fff;
    }
    .myPromotion_header {
      border-bottom: #e9cb7b 0.00001rem solid;

      .myPromotion_titleHolder {
        color: #461d09;
        background: linear-gradient(to right, #e9cb7b, #e9cb7b);
      }

      .myPromotion_goNext {
        display: flex;

        span {
          background: -webkit-linear-gradient(#461d09, #461d09);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
    }

    color: #461d09;

    .myPromotion_inputLowerBox {
      background: #ffffff;
    }

    .lowerDetails_oneBox {
      background: #3b8675;

      .lowerDetails_yellowText {
        background: -webkit-linear-gradient(#3b8675, #bf8734);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        // background:yellow;
      }
    }

    .myPromotion_lowerAdditionals {
      button {
        background: linear-gradient(to bottom, #ffe800, #e39300);
      }
    }

    .lowerAdditionals_urlHolder {
      background: #dadce7;

      color: #757575;
    }

    .linkHolder_linkbox {
      color: #511a00;

      background: #dadce7;
      .linkbox_link {
        background: linear-gradient(to bottom, #ffe800, #e38c00);
      }
    }
  }

  .myPromotion_inputUpperBox {
    width: 100%;
    height: 2rem;
    background-color: #222222;
    border-radius: 0.04rem;
    font-size: 0.15rem;
    display: flex;
    flex-direction: column;
    .myPromotionBody {
      flex: 1;
      width: 100%;
      padding: 0.1rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 0.15rem;
    }
  }

  .myPromotion_header {
    width: 100%;
    position: relative;
    height: 0.3rem;
    border: none;
    display: flex;
    border-bottom: #525252 0.00001rem solid;

    .myPromotion_titleHolder {
      width: 1rem;
      height: 0.3rem;
      padding: 0.04rem 0 0 0.2rem;
      background: linear-gradient(to right, #222222, #444444);
    }

    .myPromotion_goNext {
      // padding-top: 0.05rem;
      // margin-left: 3.85rem;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      height: 100%;
      flex: 1;
      span {
        background: -webkit-linear-gradient(#fff6c2, #ffffff);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }

  .myPromotion_detailsInputContainer {
    width: 100%;
    height: 0.81rem;
    // background:blue;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.15rem;
    ul {
      list-style: none;
    }
  }

  .detailsInputContainer_first {
    width: 2.6rem;
    height: 0.85rem;
  }

  .detailsInputContainer_second {
    width: 2.6rem;
    height: 0.8rem;
  }

  .MyPromotionButtonHolder {
    // margin:auto;
    width: 6.4rem;

    button {
      font-size: 0.12rem;
      margin-left: 2.7rem;
      background: url("../../../assets/commons/button1.png");
      background-size: contain;
      background-repeat: no-repeat;
      width: 1rem;
      height: 0.3rem;
      border: none;

      color: #511a00;
    }
  }

  // -------- Lower Box
  .myPromotion_inputLowerBox {
    width: 100%;
    height: 1.5rem;
    background: #222222;
    border-radius: 0.02rem;
    padding: 0.15rem;
  }

  .myPromotion_lowerDetails {
    width: 100%;
    padding: 0 0 0 0;
    height: 0.65rem;
    // background:blueviolet;

    ul {
      list-style: none;
      display: flex;
      justify-content: center;
      width: 100%;
      gap: 0.15rem;
      li {
        // margin-right: 0.1rem;
      }
    }
  }

  .lowerDetails_oneBox {
    font-size: 0.15rem;
    width: 2.9rem;
    height: 0.65rem;
    background-color: #1a1a1a;
    border-radius: 0.04rem;
    display: flex;
    flex-direction: column;

    .lowerDetails_yellowText {
      // margin: 0 0 0 0.95rem;
      text-align: center;
      padding: 0.1rem;
      font-size: 0.2rem;
      width: 0.8rem;
      height: 0.33rem;
      font-weight: 500;
      background: -webkit-linear-gradient(#fedf7f, #bf8734);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      // background:yellow;
    }

    .lowerDetails_whiteText {
      margin: 0 0 0 1.2rem;
      // background-color: pink;;
      font-size: 0.1rem;
    }
  }

  .myPromotion_lowerAdditionals {
    padding-top: 0.048em;
    // width: 6rem;
    width: 100%;
    margin-top: 0.05rem;
    height: 0.4rem;
    // background:pink;
    display: flex;
    // align-items: center;
    // justify-content: center;
    justify-content: center;

    button {
      width: 1.2rem;
      height: 0.3rem;
      background: linear-gradient(to bottom, #ffe800, #e38c00);
      border-radius: 0.2rem;
      font-size: 0.13rem;
      color: #511a00;
      font-weight: 600;
      border: none;
    }
  }

  .lowerAdditionals_urlHolder {
    width: 3.4rem;
    height: 0.1rem;
    border: none;
    margin-left: 0.35rem;
    padding-top: 0.03rem;
    height: 0.3rem;
    background: #000;
    border-top-left-radius: 0.2rem;
    border-bottom-left-radius: 0.2rem;

    display: flex;

    padding-left: 0.1rem;
    color: white;
    margin-left: 0.1rem;
    font-size: 0.12rem;
    padding-top: 0.06rem;

    span {
      padding-left: 0.05rem;
      padding-right: 0.05rem;
      margin-left: 0.1rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      height: 0.2rem;
      width: 2.4rem;
    }
  }

  .linkHolder_linkbox {
    color: #511a00;
    // border-bottom-left-radius:0.2rem;
    font-size: 0.14rem;
    // width: 0.7rem;
    height: 0.3rem;
    background: #000;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
    // border-top-right-radius: 0.1rem;
    // border-bottom-right-radius:0.2rem;
    .linkbox_link {
      width: 1.2rem;
      font-weight: 700;
      height: 0.3rem;
      border-top-right-radius: 0.4rem;
      border-bottom-left-radius: 0.8rem;
      border-bottom-right-radius: 0.4rem;
      background: linear-gradient(to bottom, #ffe800, #e38c00);
      text-align: center;
      padding-top: 0.04rem;
    }
  }
}

.myPromotion {
  overflow-y: scroll;
}

// @media screen and (min-height: 240px) {
//   // .myPromotion {
//   //   height: 35%;
//   // }
// }
// @media screen and (min-height: 300px) {
//   // .myPromotion {
//   //   height: 47%;
//   // }
// }
// @media screen and (min-height: 360px) {
//   .myPromotion {
//     height: 47%;
//   }
// }
// @media screen and (min-height: 400px) {
//   .myPromotion {
//     height: 65%;
//   }
// }
// @media screen and (min-height: 450px) {
//   .myPromotion {
//     height: 70%;
//   }
// }
// @media screen and (min-height: 500px) {
//   .myPromotion {
//     height: 100%;
//   }
// }
