.gameBalanceCard {
  width: 100%;
  height: 0.5rem;
  background: var(--vip-balance-bg);
  color: var(--vip-balance-text);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.08rem;
  padding: 0.1rem;
  gap: 0.1rem;

  .gameBalanceName {
    flex: 1;
  }

  .gameBalanceAmount {
    text-align: right;
    width: 1rem;
    color: var(--vip-balance-amount);
    margin-left: 1rem;
  }
}
