.overlay {
  background: rgba(0, 0, 0, 0.472);
  height: calc(100% - 0.4rem);
  width: 100%;
  transform: translateZ(0);
  z-index: 13 !important;
  position: absolute !important;
  top: 0.4rem !important;
  font-size: 0.08rem;
  left: 0;
  // padding-top: 0.4rem;
  &[data-theme="brownGold"] {
    .gameLeftModalContainer {
      background: #ededed;
      .modalLeftGameBody {
        .GameModalInstruction {
          .GameInstructionContainer {
            background: #ffffff;
            color: black;
          }
        }
        .gameInfoTableWrapper {
          .gameInfoTableContainer {
            background: #ededed;
          }
          .gameInfoTableContainer22 {
            color: #888;
            .sidetablebutton:nth-child(even) {
              color: #000000 !important;
              background-color: #ffffff;
            }
            .sidetablebutton:nth-child(odd) {
              color: #000000 !important;
              background-color: #f7ede7;
            }
          }
        }
      }
      .GameLeftModalSwiperHeader {
        background-color: #ffffff;
        ul {
          li {
            color: #888888;
          }
        }
        .activeGameHeaderSection {
          background: #735c4b;
          color: #ffffff;
        }
      }
    }
    .gameInfoTableContainer2,
    .gameInfoTableContainer22 {
      background: #fff !important;
    }
  }

  &[data-theme="yellowWhite"] {
    .gameLeftModalContainer {
      background: #fefeff;
      &:has(.GameModalInstruction) {
        background: #ebedf1;
      }
      .modalLeftGameBody {
        .GameModalInstruction {
          .GameInstructionContainer {
            background: #fefeff;
            color: #c5915a;
          }
        }
        .gameInfoTableWrapper {
          .gameInfoTableContainer {
            background: #fefeff;
            :global {
              table {
                tbody {
                  tr {
                    td:first-child {
                      span{
                        background: -webkit-linear-gradient(#a11300, #a11300) !important;
                        -webkit-background-clip: text !important;
                        -webkit-text-fill-color: transparent !important;
                      }
                    }
                  }
                }
              }
            }
          }
          .gameInfoTableContainer22 {
            .sidetablebutton:nth-child(even) {
              color: #a11300 !important;
              background-color: #fafafa;
            }
            .sidetablebutton:nth-child(odd) {
              color: #a11300 !important;
              background-color: #ebedf1;
            }
            .sidetablebutton .pAmount {
              color: #c5915a !important;
            }
          }
        }
      }
      .GameLeftModalSwiperHeader {
        background-color: #0a0a0a;
        ul {
          li {
            color: #fbfbff;
          }
        }
        .activeGameHeaderSection {
          background: #ffc400;
          color: #511a00;
        }
      }
    }
    .gameInfoTableContainer2,
    .gameInfoTableContainer22 {
      background: #fafafa !important;
    }
  }
  &[data-theme="greenYellow"] {
    .gameLeftModalContainer {
      background: linear-gradient(to left, #007658, #055848, #06362d);
      .modalLeftGameBody {
        .GameModalInstruction {
          .GameInstructionContainer {
            background-color: #d6eae4;
            padding: 0.1rem;
            color: #511a00;
            border-radius: 0.2rem;
          }
        }
        .gameInfoTableWrapper {
          .gameInfoTableContainer {
            background: #7ab9a9;
          }
          .gameInfoTableContainer22 {
            .sidetablebutton:nth-child(even) {
              color: #511a00 !important;
              background-color: #d6eae4;
            }
            .sidetablebutton:nth-child(odd) {
              color: #511a00 !important;
              background-color: #7ab9a9;
            }
            .sidetablebutton {
              span:nth-child(3) {
                color: #2DE221 !important;
              }
            }
          }
        }
      }
      .GameLeftModalSwiperHeader {
        background-color: #ffffff;
        ul {
          li {
            color: #511a00;
          }
        }
        .activeGameHeaderSection {
          background: linear-gradient(
            to top,
            #e39300,
            #e39300,
            #ffe800,
            #ffe800,
            #ffffff
          );

          border: 0.01rem solid #fae37c;
          color: #511a00;
        }
      }
    }
    .gameInfoTableContainer2,
    .gameInfoTableContainer22 {
      background: #d6eae4 !important;
    }
  }
  &[data-theme="skyBlue"] {
    .gameLeftModalContainer {
      background: #f4f6f9;
      .modalLeftGameBody {
        .GameModalInstruction {
          .GameInstructionContainer {
            background-color: #ffffff;
            padding: 0.1rem;
            color: #888888;
            border-radius: 0.2rem;
          }
        }
        .gameInfoTableWrapper {
          .gameInfoTableContainer22 {
            .sidetablebutton:nth-child(even) {
              color: #000000 !important;
              background-color: #ffeec3;
            }
            .sidetablebutton:nth-child(odd) {
              color: #000000 !important;
              background: #f9f9f9;
            }
          }
        }
      }
      .GameLeftModalSwiperHeader {
        background-color: #fff;
        ul {
          li {
            color: #888888;
          }
        }
        .activeGameHeaderSection {
          background: linear-gradient(to left, #15d9f8, #037dfb);

          color: #ffffff;
        }
      }
    }
    .gameInfoTableContainer2,
    .gameInfoTableContainer22 {
      background: #ffffff !important;
    }
  }
  &[data-theme="darkBlue"] {
    .gameLeftModalContainer {
      background: linear-gradient(to right, #4d6296, #29334b);
      .modalLeftGameBody {
        .GameModalInstruction {
          .GameInstructionContainer {
            background-color: #d7e8f0;
            padding: 0.1rem;
            color: #29468d;
            border-radius: 0.2rem;
          }
        }
        .gameInfoTableWrapper {
          .gameInfoTableContainer {
            background: #4d6296;
            .pullToRefresh {
              background: #4d6296;
              :global {
                table {
                  tbody {
                    tr:nth-child(even) {
                      background: linear-gradient(90deg, #4D6296 0%, #8498C9 100%);
                    }
                    tr:nth-child(odd) {
                      background: #4D6296;
                    }
                    tr {
                      span {
                        font-size: 0.15rem !important;
                      }
                    }
                  }
                }
              }
            }
          }
          
          .gameInfoTableContainer22 {
            .pullToRefresh {
              background: #4d6296;
            }
            .sidetablebutton:nth-child(even) {
              background: linear-gradient(90deg, #4D6296 0%, #8498C9 100%);
            }
            .sidetablebutton:nth-child(odd) {
              background: #4D6296;
            }
            .dbColor {
              font-weight: 500;
            }
          }
        }
      }
      .GameLeftModalSwiperHeader {
        background-color: #00000080;
        ul {
          li {
            color: #fff;
          }
        }
        .activeGameHeaderSection {
          background: linear-gradient(to top, #8aeaff, #ffffff);

          color: #162752;
        }
      }
    }
    .gameInfoTableContainer2,
    .gameInfoTableContainer22 {
      background: #d7e8f0 !important;
    }
  }
  &[data-theme="redGold"] {
    .gameLeftModalContainer {
      background: #fdf8f8;
      .modalLeftGameBody {
        .GameModalInstruction {
          .GameInstructionContainer {
            background-color: #f4e8e8;
            padding: 0.1rem;
            color: #080000;
            border-radius: 0.2rem;
          }
        }
        .gameInfoTableWrapper {
          .gameInfoTableContainer {
            background: #fdf8f8;
            :global {
              table {
                tbody {
                  tr:nth-child(even){
                    background: linear-gradient(90deg, #EED1D1 0%, #DEC3C3 100%);
                  }
                  
                  tr:nth-child(odd){
                    background: linear-gradient(90deg, #F4E8E8 0%, #F1DBDB 100%);
                  }
                  td:nth-child(2) {
                    span div span {
                      background: -webkit-linear-gradient(#fff, #fff);
                      -webkit-background-clip: text;
                      -webkit-text-fill-color: transparent;
                    }
                  }
                }
              }
            }
          }
          .gameInfoTableContainer22 {
            .sidetablebutton:nth-child(even) {
              background-color: #f4e8e8;
              color: #080000;
            }
            .sidetablebutton:nth-child(odd) {
              background: #dec3c3;
              color: #080000;
            }
          }
        }
      }
      .GameLeftModalSwiperHeader {
        background: rgba(0, 0, 0, 0.5);
        ul {
          li {
            color: #fbfbff;
          }
        }
        .activeGameHeaderSection {
          background: linear-gradient(to bottom, #fbeeb1, #facf6b);
          border: 0.01rem solid #fae37c;
          color: #511a00;
        }
      }
    }
    .gameInfoTableContainer2,
    .gameInfoTableContainer22 {
      background: #f4e8e8 !important;
    }
  }
  &[data-theme="whiteGold"] {
    .gameLeftModalContainer {
      background: #dadce7;
      .modalLeftGameBody {
        .GameModalInstruction {
          .GameInstructionContainer {
            background-color: #ffffff;
            padding: 0.1rem;
            color: #435689;
            border-radius: 0.2rem;
          }
        }
        .gameInfoTableWrapper {
          .gameInfoTableContainer {
            background: #f9f9f9;
            :global {
              table {
                tbody {
                  td:nth-child(2) {
                    span div span {
                      background: -webkit-linear-gradient(#fff, #fff);
                      -webkit-background-clip: text;
                      -webkit-text-fill-color: transparent;
                    }
                  }
                }
              }
            }
          }
          .gameInfoTableContainer22 {
            .sidetablebutton:nth-child(even) {
              color: #435689 !important;
              background-color: #ffeec3;
            }
            .sidetablebutton:nth-child(odd) {
              background: #f9f9f9;
              color: #435689 !important;
            }
          }
        }
      }
      .GameLeftModalSwiperHeader {
        background-color: #ffffff;
        ul {
          li {
            color: #435689;
          }
        }
        .activeGameHeaderSection {
          background: linear-gradient(to bottom, #fbeeb1, #facf6b);
          border: 0.01rem solid #fae37c;
          color: #511a00;
        }
      }
    }
    .gameInfoTableContainer2,
    .gameInfoTableContainer22 {
      background: #ffffff !important;
    }
  }

  .gameLeftModalContainer {
    width: 4.5rem;
    font-size: 0.15rem;
    background: #161616;
    border: solid 0.001rem #8d7c4f;
    color: white;
    height: 100%;
    position: relative;
    z-index: 12;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 0.1rem;
    // padding-bottom: 0.2rem;
    .GameLeftModalSwiperHeader {
      width: 90%;
      background-color: #222222;
      height: 0.28rem;
      margin-top: 0.18rem;
      border-radius: 0.2rem;

      ul {
        display: flex;
        list-style: none;
        height: 100%;
        justify-content: space-between;

        li {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 0.28rem;
          padding: 0.025rem 0;
          width: 1.13rem;
          text-align: center;
        }
      }
      .activeGameHeaderSection {
        width: 1.13rem;
        height: 100%;
        border-radius: 0.2rem;
        background: linear-gradient(to top, #fbeeb1, #facf6b);
        color: #651d00;
        font-weight: 700;
        padding-bottom: 0.03rem;
      }
    }
    .modalLeftGameBody {
      // position: relative;
      flex: 1;
      width: 90%;
      overflow-y: auto;
      // height: 90%;
      .GameModalInstruction {
        padding: 0.1rem 0 0.1rem 0;
        overflow: auto;
        height: 100%;
        border-radius: 0.2rem;
        position: relative;
        .GameInstructionContainer {
          height: 100%;
          overflow-y: scroll;
          background: #2c2c2c;
          padding: 0.08rem;
          border-radius: 0.08rem;
          &::-webkit-scrollbar {
            display: none;
          }
        }
      }
      .gameInfoTableWrapper {
        padding: 0.1rem 0;
        display: flex;
        width: 100%;
        overflow-y: scroll;
        height: 100%;
        .gameInfoTableContainer {
          height: 100%;
          width: 100%;
          overflow-y: scroll;
          background: #222222;
          border-radius: 0.08rem;

          &::-webkit-scrollbar {
            display: none;
          }
        }
        .gameInfoTableContainer2 {
          height: 100%;
          width: 3.5rem;
          // margin: 0.15rem 0 0.15rem 0.15rem;

          background: #222222;

          border-radius: 0.08rem;
          border-bottom-right-radius: 0;
          border-top-right-radius: 0;

          &::-webkit-scrollbar {
            display: none;
          }
        }
        .gameInfoTableContainer22 {
          flex-direction: column;
          height: 100%;
          width: 100%;
          // margin: 0.15rem 0.15rem 0.15rem 0;
          display: flex;

          background: #222222;

          border-radius: 0.08rem;
          overflow: scroll;

          &::-webkit-scrollbar {
            display: none;
          }
          .sidetablebutton:nth-child(even) {
            background-color: #2c2c2c;
          }
          .sidetablebutton:nth-child(odd) {
            background-color: #222222;
          }
        }
      }
    }
  }

  .dbColor {
    background-image: linear-gradient(to top, #ffe29b, #ffffff);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
  }
}

.pullToRefresh {
  :global {
    .ptr__pull-down > div {
      p {
        font-size: 0.15rem;
        color: #f6da85;
        text-align: center !important;
      }
    }
  }
}
