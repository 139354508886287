@import "src/styles/_helpers.scss";

.wrapper {
  display: flex;
  flex: 1;
  gap: size(2);
  .betAmountBg {
    position: relative;
    background-image: url("../../../../../assets/commons/baccarat/bet-amount-bg.png");
    background-repeat: no-repeat;
    background-size: contain;
    height: size(18);
    width: size(15);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: size(0.1);
    border-radius: size(0.5);
    overflow: hidden;
    cursor: pointer;
    .rollSpace {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 80%;
      width: 80%;
      .text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        p {
          user-select: none;
          font-weight: 600;
          font-size: size(3);
          font-style: normal;
          color: #ffffff;
          height: size(3.4);
          &:nth-child(2) {
            font-weight: 100;
            font-size: size(2.2);
          }
        }
      }
      .chip {
        user-select: none;
        position: absolute;
        height: size(2);
        width: size(2);
      }
    }
    .bottom {
      background-color: rgba(0, 0, 0, 0.3);
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 85%;
      border-radius: size(2);
      margin-bottom: size(1);
      img {
        user-select: none;
        margin: size(0.5);
        height: size(1.8);
        width: auto;
      }
      span {
        user-select: none;
        margin: size(0.5);
        line-height: size(1.5);
        font-size: size(1.5);
        color: #ffe500;
      }
    }

    .onClickOverlay{
      position: absolute;
      z-index: 11;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: transparent;
    }
  }

  .blink{
    animation: blinker 1s linear 5;
  }
}

@keyframes blinker {
  0% {
      opacity: 0;
  }
  50% {
      opacity: 1;
  }
  100% {
    opacity: 0;
    visibility: visible;
  }
}