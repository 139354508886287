.container {
  width: 100%;
  height: 100%;
  overflow: scroll;
}

.gameBalance_mainWrapper {
  width: 100%;
  height: 100%;
  font-size: 0.15rem;
  position: relative;
}

.noData {
  height: 100%;
  width: 100%;
  position: relative;
}

.gameBalanceCardContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 0.1rem;

  .noDataWrapper {
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.gameBalanceCard {
  width: 100%;
  height: 0.5rem;
  background: #2c2c2c;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.08rem;
  padding: 0.1rem;
  gap: 0.1rem;
}

.gameBalanceName {
  flex: 1;
}

.gameBalanceAmount {
  text-align: right;
  width: 1rem;
  color: #42f166;
}

.noDataWrapper{
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pullToRefresh {
  :global {
    .ptr__pull-down > div {
      p {
        font-size: 0.15rem;
        color: #f6da85;
        text-align: center !important;
      }
    }
  }
}
