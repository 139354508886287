@import "src/styles/helpers";

.listSmallWrapper {
  display: flex;
  justify-content: center;
  // align-items: flex-start;
  flex-direction: column;
  height: 100%;
  overflow: auto;
  overflow-y: scroll;
  overflow-x: scroll;
  position: relative;
  -webkit-overflow-scrolling: touch;

  // .slidesWrapper{
  //   display: flex;
  //   flex-direction: column;
  //   justify-content: center;
  //   align-items: center;
  //   position: relative;
  // }

  // :global{
  //   .smallIconSwiper {
  //     width: 100%;
  //   }
  // }
  // :global {
  //   .swiper {
  //     width: 100%;
  //     height: 2.5rem;
  //     margin-left: auto;
  //     margin-right: auto;
  //     padding-left: 0.1rem;
  //     display: flex;
  //     // justify-content: center;
  //   }
  //   .swiper-slide {
  //     height: 1.12rem !important;
  //     width: 1.2rem !important;
  //     // margin: 0.1rem;
  //     display: flex;
  //     justify-content: center;
  //     align-items: center;
  //   }
  //   .swiper-wrapper {
  //     // grid-gap: 0.1rem;
  //     display: flex;
  //     justify-content: flex-start;
  //     align-items: center;
  //     padding-top: 0.1rem;
  //   }

  //   @media screen and (min-width: 2560px) {
  //     .swiper {
  //       width: 100%;
  //       height: 2.6rem;
  //     }
  //     .swiper-slide {
  //       height: 1.2rem !important;
  //       width: 1.35rem !important;
  //       margin: 0 1vh 0 1vh;
  //     }
  //     .swiper-wrapper {
  //       // grid-gap: 3vh;
  //     }
  //   }
  // }

  // .noGames {
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  //   height: 0.6rem;
  //   width: 100%;
  //   background-image: url("../../../../../../Assets/img/no-games-bg.png");
  //   background-position: center center;
  //   background-size: cover;
  //   background-repeat: no-repeat;
  //   gap: 0.15rem;
  //   position: relative;
  //   top: -0.2rem;
  //   bottom: 0;
  //   margin: auto;
  //   img {
  //     height: 0.25rem;
  //     width: 0.25rem;
  //   }
  //   span {
  //     color: white;
  //     font-size: 0.2rem;
  //   }
  // }

  // .iconHolder {
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  //   height: 1rem;
  //   width: 1rem;
  //   img {
  //     position: inherit;
  //     cursor: pointer;
  //     width: auto;
  //     height: 100%;
  //     object-fit: cover;
  //     border-radius: 0.11rem;
  //   }
  // }
  .rowsContainer {
    // margin-left: 0.15rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    // gap: 0.03rem;
    height: 100%;
    position: relative;
    .firstRow,
    .secondRow {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 0.15rem;
      width: auto;
      height: 50%;
      position: relative;
      .iconHolder {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 98%;
        // width: auto !important;
        // flex: 1;
        position: relative;
        img {
          position: inherit;
          cursor: pointer;
          width: auto;
          height: 100%;
          object-fit: contain;
          border-radius: 0.11rem;
        }
      }
      .isMaintenance{
        pointer-events: none;
      }
    }
  }
}

.type3Overlay {
  position: relative;
  height: calc( 100% - 0.4rem);
  // top: size(-2);
  // :global {
  //   .swiper-slide {
  //     height: size(15) !important;
  //     width: size(15) !important;
  //   }
  // }

  // @media screen and (min-width: 812px) {
  //   top: -0.09rem;
  //   :global {
  //     .swiper-slide {
  //       height: 0.95rem !important;
  //       width: 0.95rem !important;
  //     }
  //   }
  // }

  // @media screen and (min-width: 2048px) {
  //   top: size(-5);
  //   :global {
  //     .swiper-slide {
  //       height: size(18) !important;
  //       width: size(18) !important;
  //     }
  //   }
  // }
}

.noDataOverlay {
  top: -0.4rem;
}

// @media screen and (max-height: 320px) and (orientation: landscape) {
//   .iconHolder {
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     height: 0.7rem !important;
//     width: 0.7rem !important;
//     img {
//       position: inherit;
//       cursor: pointer;
//       height: 0.8rem !important;
//       width: 0.8rem !important;
//       object-fit: cover;
//       border-radius: 0.11rem;
//     }
//   }
// }
// @media screen and (max-height: 230px) and (orientation: landscape) {
//   .iconHolder {
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     height: 0.5rem !important;
//     width: 0.5rem !important;
//     img {
//       position: inherit;
//       cursor: pointer;
//       height: 0.55rem !important;
//       width: 0.55rem !important;
//       object-fit: cover;
//       border-radius: 0.11rem;
//     }
//   }
// }
// @media screen and (max-height: 270px) and (orientation: landscape) {
//   .iconHolder {
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     height: 0.55rem !important;
//     width: 0.55rem !important;
//     img {
//       position: inherit;
//       cursor: pointer;
//       height: 0.6rem !important;
//       width: 0.6rem !important;
//       object-fit: cover;
//       border-radius: 0.11rem;
//     }
//   }
// }
// //Honor browser
// @media screen and (height: 303px) and (width: 776px) and (orientation: landscape) {
//   .iconHolder {
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     height: 0.9rem !important;
//     width: 0.75rem !important;
//     img {
//       position: inherit;
//       cursor: pointer;
//       height: 0.75rem !important;
//       width: 0.75rem !important;
//       object-fit: cover;
//       border-radius: 0.11rem;
//     }
//   }
// }
