.s2accordion_wrapper {
  width: 100%;
  .s2accordion_item {
    width: 100%;
    margin-bottom: 5px;
    cursor: pointer;

    .accordion_container {
      background: rgba(44, 44, 44, 1);
      border-radius: 0.08rem;
      height: 0.45rem;
      width: 100%;
      font-size: 0.1rem;
      display: flex;
      justify-content: space-between;

      .greenPlus {
        color: rgba(20, 255, 0, 1);
        width: 0.3rem;
        height: 0.3rem;
        margin: 0.04rem;
        padding-top: 0.08rem;
        font-size: 0.09rem;
        text-align: center;
      }
      .s2task_button {
        button {
          color: #651d00;
          width: 0.4rem;
          height: 0.2rem;
          background: linear-gradient(to top, #ffe800, #e38c00);
          border: none;
          font-size: 0.09rem;
          font-weight: 700;
          border-radius: 0.15rem;
          margin-top: 0.12rem;
          margin-left: 0.08rem;
          border-bottom: white 0.01rem solid;
        }
      }
      .accordionContainer_image {
        width: 0.4rem;
        height: 0.4rem;
        padding: 0.04rem;
        border-radius: 0.1rem;
        margin-left: 0.05rem;
      }

      .accordionContainer_details {
        padding: 0.02rem;
        margin-left: -0.04rem;
        font-size: 0.09rem;
        .brief_info {
          display: flex;

          span {
            width: 1.4rem;
            height: 0.14rem;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }

          img {
            width: 0.1rem;
            height: 0.1rem;
            margin-left: 0.02rem;
          }
        }

        .taskbarBorder {
          height: 0.08rem;
          width: 1.4rem;
          margin: 0.005rem 0.01;
          padding: 0.006rem;
          display: inline-block;
          position: relative;
          z-index: 0;
          font-size: 0.04rem;
          color: white;
          display: flex;

          .progressNum {
            position: absolute;
            left: 0.6rem;
            top: -0.005rem;
            font-size: 0.07rem;
          }

          .taskbar {
            position: relative;
            z-index: -1;
            border-radius: 15rem;
            width: 0.3rem;
            height: 0.068rem;
            background: linear-gradient(
              to top,
              rgba(254, 229, 135, 1),
              rgba(255, 176, 43, 1)
            );
          }
        }

        .taskbarBorder::before {
          content: "";
          position: absolute;
          z-index: -1;
          inset: 0;
          padding: 0.008rem;
          border-radius: 15rem;
          background: var(--var-gradient);
          -webkit-mask: linear-gradient(rgb(255, 255, 255) 0 0) content-box,
            linear-gradient(#fff 0 0);
          mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
          -webkit-mask-composite: xor;
          mask-composite: exclude;
        }
      }
    }

    .accordion_details_show {
      background: rgba(34, 34, 34, 1);
      display: block;
      width: 100%;
      border-bottom-left-radius: 0.08rem;
      border-bottom-right-radius: 0.08rem;
      margin-bottom: 0.08rem;
      font-size: 0.09rem;
      letter-spacing: -0.003rem;
      padding: 0.07rem;

      .accordionDetails_title {
        background: -webkit-linear-gradient(#ffffff, #fff6c2);
        -webkit-background-clip: text !important;
        -webkit-text-fill-color: transparent !important;
        font-size: 0.12rem;
        font-weight: 900;
      }
    }
    .accordion_details_hide {
      display: none;
    }
  }
  &[data-theme="brownGold"] {
    .accordion_container {
      background-color: #c4a994;
      color: #fff;
      .accordionContainer_details {
        .taskbarBorder::before {
          background: rgba(130, 91, 61, 0.5);
          border: 0.01rem solid #ffeda7;
          -webkit-mask: unset;
        }
      }
    }
    .accordion_details_show {
      background: #ededed;
      color: #888888;
      .accordionDetails_title {
        background: -webkit-linear-gradient(#735c4b, #735c4b);
      }
    }
  }
  &[data-theme="yellowWhite"] {
    .accordion_container {
      background-color: #ebedf1;
      color: #c5915a;
      .accordionContainer_details {
        > div:first-child {
          color: #a11300;
        }
        .taskbarBorder::before {
          background: #0a0a0a;
          border: 0.01rem solid #ffeda7;
          -webkit-mask: unset;
        }
      }
      .greenPlus {
        color: #000;
      }
    }
    .accordion_details_show {
      background: #f8f8f8;
      color: #bea288;
      .accordionDetails_title {
        background: -webkit-linear-gradient(#735c4b, #735c4b);
      }
    }
  }
  &[data-theme="greenYellow"] {
    .accordion_container {
      background-color: #0e9473;
      color: #fff;
      .accordionContainer_details {
        .taskbarBorder::before {
          background: rgba(0, 0, 0, 0.5);
          border: 0.01rem solid #ffeda7;
          -webkit-mask: unset;
        }
      }
    }
    .accordion_details_show {
      background: #f2f2f2;
      color: #511a00;
      .accordionDetails_title {
        background: -webkit-linear-gradient(#511a00, #511a00);
      }
    }
  }
  &[data-theme="skyBlue"] {
    .accordion_container {
      background-color: #0ca0e9;
      color: #fff;
      .accordionContainer_details {
        .taskbarBorder::before {
          background: #0081ca;
          border: 0.01rem solid #3beaff;
          -webkit-mask: unset;
        }
      }
    }
    .accordion_details_show {
      background: #fff;
      color: #888888;
      .accordionDetails_title {
        background: -webkit-linear-gradient(#735c4b, #735c4b);
      }
    }
  }
  &[data-theme="darkBlue"] {
    .accordion_container {
      background: linear-gradient(90deg, #4d6296 0%, #8498c9 100%);
      color: #fff;
      .accordionContainer_details {
        .taskbarBorder::before {
          background: rgba(0, 0, 0, 0.5);
          border: 0.01rem solid #8aeaff;
          -webkit-mask: unset;
        }
      }
    }
    .accordion_details_show {
      background: linear-gradient(270deg, #29334b 0%, #4d6296 100%);
      color: #ffffff;
      .accordionDetails_title {
        background: linear-gradient(180deg, #ffffff 0%, #8aeaff 100%);
      }
    }
  }
  &[data-theme="redGold"] {
    .accordion_container {
      background: linear-gradient(90deg, #af0100 0%, #ff6b00 100%);
      color: #fff;
      .accordionContainer_details {
        .taskbarBorder::before {
          background: rgba(0, 0, 0, 0.5);
          border: 0.01rem solid #ffeda7;
          -webkit-mask: unset;
        }
      }
    }
    .accordion_details_show {
      background: linear-gradient(90deg, #f4e8e8 0%, #f1dbdb 100%);
      color: #080000;
      .accordionDetails_title {
        background: linear-gradient(180deg, #6a1211 0%, #6a1211 100%);
      }
    }
  }
  &[data-theme="whiteGold"] {
    .accordion_container {
      background: #e9cb7b;
      color: #461d09;
      .accordionContainer_details {
        .taskbarBorder::before {
          background: rgba(0, 0, 0, 0.5);
          border: 0.01rem solid #ffeda7;
          -webkit-mask: unset;
        }
      }
      .greenPlus {
        color: #06b100;
      }
    }
    .accordion_details_show {
      background: #f9f9f9;
      color: #435689;
      .accordionDetails_title {
        background: linear-gradient(180deg, #1d2d61 0%, #1d2d61 100%);
      }
    }
  }
}
.title {
  font-size: 0.1rem;
  img {
    width: 0.13rem;
  }
}
