.loader {
  z-index: 100;
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex !important;
  justify-content: center;
  align-items: center;
  background-color: rgba(0.5, 0.5, 0.5, 0.5);
}
