.styleLabeled1[data-theme="blackGold"] {
  background: -webkit-linear-gradient(#ffffff, #ffe29b);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.styleLabeled1[data-theme="brownGold"] {
  background: -webkit-linear-gradient(#ffffff, #ffe29b);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.styleLabeled1[data-theme="yellowWhite"] {
  background: -webkit-linear-gradient(#a11300, #a11300);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.styleLabeled1[data-theme="greenYellow"] {
  background: -webkit-linear-gradient(#facd12, #facd12);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.styleLabeled1[data-theme="skyBlue"] {
  background: -webkit-linear-gradient(#ffffff, #fff6c2);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.styleLabeled1[data-theme="darkBlue"] {
  background: -webkit-linear-gradient(#ffffff, #8aeaff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.styleLabeled1[data-theme="redGold"] {
  background: -webkit-linear-gradient(#ffffff, #fff6c2);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.styleLabeled1[data-theme="whiteGold"] {
  background: -webkit-linear-gradient(#435689, #435689);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.styleLabel2[data-theme="blackGold"] {
  background: -webkit-linear-gradient(#ffffff, #ffe29b);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.styleLabel2[data-theme="brownGold"] {
  background: -webkit-linear-gradient(#FFF, #FFE29B );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.styleLabel2[data-theme="yellowWhite"] {
  background: -webkit-linear-gradient(#ffffff, #fefeff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.styleLabel2[data-theme="greenYellow"] {
  background: -webkit-linear-gradient(#ffffff, #fff6c2);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.styleLabel2[data-theme="skyBlue"] {
  background: -webkit-linear-gradient(#ffffff, #fff6c2);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.styleLabel2[data-theme="darkBlue"] {
  background: -webkit-linear-gradient(#ffffff, #8aeaff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.styleLabel2[data-theme="redGold"] {
  background: -webkit-linear-gradient(#ffffff, #fff6c2);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.styleLabel2[data-theme="whiteGold"] {
  background: -webkit-linear-gradient(#461d09, #461d09);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
