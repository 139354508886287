@import '/src/styles/_helpers.scss';

.header {
  display: flex;
  width: 100%;
  height: 0.55rem;
  background: var(--header-bg-img);
  background-size: 100% 100% !important;
  background-repeat: no-repeat;
  z-index: 2;
}

.vippart {
  width: 24%;
  display: flex;
  padding-left: 2px;
  align-items: center;

  .avatarContainer {
    position: relative;
    @include flex-xy;
    margin-left: 0.1rem;
    width: 0.47rem;
    height: 0.47rem;
    border-radius: 50%;
    border: 0.02rem solid transparent;
    .avatarPhoto {
      width: 100%;
      height: 100%;
      padding: 0.01rem;
      border-radius: 50%;
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
      margin: -1.2px;
      border-radius: inherit;
      background: var(--profile-bg);
    }
  }

  .userDetailsContainer {
    width: 1.3rem;
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
    padding: 0.05rem 0.15rem 0.05rem 0.05rem;
    gap: 0.01rem;
    .userDetails {
      height: 80%;
      display: flex;
      justify-content: space-between;
      .userInfo {
        @include flex-xy(center, flex-start);
        flex-direction: column;
        span:nth-child(1) {
          font-size: 0.12rem;
          color: var(--common-header);
        }
        .vip {
          font-size: 0.1rem;
          color: var(--vip-header);
        }
      }
      .copyIcon {
        position: relative;
        @include flex-xy(flex-end, center);
        height: 100%;
        width: 0;
        img {
          position: relative;
          right: 0;
          height: 60%;
          width: auto;
        }
        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-image: linear-gradient(
            to right,
            rgba(255, 0, 0, 0.5),
            rgba(0, 255, 0, 0.5)
          );
          pointer-events: none; /* Ensure the gradient layer doesn't interfere with any interactions on the image */
        }
      }
    }

    .vipBar {
      @include flex-xy;
      width: 85%;
      height: 0.08rem;
      border-radius: 1rem;
      overflow: hidden;
      position: relative;
      background: var(--vip-outline-bar);
      .vipBarBorder {
        height: 0.06rem;
        width: 98%;
        position: relative;
        display: flex;
        align-items: center;
        background: var(--vip-bg-bar);
        z-index: 1;
        border-radius: 1rem;

        .vipBarExp {
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          z-index: -10;
          border-radius: 12rem;
          background: var(--vip-progress-bar);
        }
      }
    }
    .btn_wrapper {
      width: 100%;
      display: flex;
      justify-content: space-between;
      .loginButton {
        position: relative;
        background-color: transparent;
        border: none;
        width: 48%;
        img {
          height: auto;
          width: 100%;
        }
      }
    }

  }
}

.settingContainer {
  @include flex-xy;
  flex-direction: column;
  margin-right: 0.05rem;
  padding: 0.05rem 0;
  position: relative;
  width: 0.25rem;
  > img {
    width: 80%;
    height: auto;
  }
  .settingText {
    font-size: 0.1rem;
    background: var(--settings-header);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }
}
