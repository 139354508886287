.overlay {
  background: rgba(0, 0, 0, 0.472);
  height: 100%;
  width: 100%;
  transform: translateZ(0);
  z-index: 12 !important;
  position: absolute;
  top: 0;
  font-size: 0.08rem;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  .modalContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 4rem;
    // height: 2.9rem;
    border-radius: 0.08rem;
    overflow: hidden;
    border: 2px solid #8d7c4f;
    .modalHeader {
      position: relative;
      width: 100%;
      height: 0.4rem;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      span {
        font-family: "FZZhengHeiS-B-GB";
        font-style: normal;
        font-weight: 700;
        font-size: 0.2rem;
        color: #511a00;
        text-shadow: 0 4px 4px #fef2b9;
      }
      img {
        position: absolute;
        right: 0.1rem;
        height: 50%;
        width: auto;
      }
    }
    .modalBody {
      //   height: 2.5rem;
      flex: 1;
      width: 100%;
      position: relative;
      padding: 0.15rem;
      font-size: 0.13rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 0.1rem;
      > div:first-child {
        width: 100%;
      }
      :global {
        .feedbackSuggestion__control {
          background-color: #1a1a1a;
          border-color: #1a1a1a;
          border-radius: 0.3rem;
          padding: 0.05rem;
          width: 100%;
          box-shadow: none;
        }
        .feedbackSuggestion__indicator-separator {
          background-color: #1a1a1a;
        }
        .feedbackSuggestion__dropdown-indicator {
          height: 100% !important;
          width: auto !important;

          svg {
            margin-right: 0.1rem;
            color: #757575;
            height: 100% !important;
            width: auto !important;
          }
        }
      }

      .textArea {
        width: 100%;
        background-color: #1a1a1a;
        border-color: #1a1a1a;
        border-radius: 0.1rem;
        font-size: 0.13rem;
        resize: none;
        padding: 0.07rem;
        color: #fff;
        caret-color: #fff;
        &:focus {
          outline: none;
        }
      }

      .contactInfo {
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 0.05rem;
        span {
          font-size: 0.13rem;
        }
        input {
          height: 0.3rem;
          font-size: 0.13rem;
          width: 100%;
          padding: 0.1rem;
          border-radius: 0.3rem;
          background-color: #1a1a1a;
          border: none;
          caret-color: #fff;
          color: #fff;
          &:focus {
            outline: none;
          }
        }
      }

      .button {
        background-image: url("../../../assets/commons/button1.png");
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        width: 1rem;
        height: 0.35rem;
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          position: relative;
          top: -0.01rem;
          font-family: "FZZhengHeiS-B-GB";
          font-style: normal;
          font-size: 0.12rem;
          color: #651d00;
        }
      }
    }

    //overlays for themes
    &[data-theme="blackGold"] {
      color: #f0cb5a;
      :global {
        .feedbackSuggestion__control {
          background-color: #1a1a1a;
          border-color: #1a1a1a;
        }
        .feedbackSuggestion__input-container {
          color: #fff;
        }
        .feedbackSuggestion__single-value {
          color: white;
        }
      }
      .textArea,
      input {
        background-color: #1a1a1a;
        border: none;
        caret-color: #fff;
        color: #fff;
      }
      .modalHeader {
        background-image: url("../../../assets/blackGold/other_modal/OtherModalHeader.png");
      }
      .modalBody {
        background: linear-gradient(
          270deg,
          #1f1e1b 0%,
          #1e1b19 26.56%,
          #262520 100%
        );
      }
    }
    &[data-theme="brownGold"] {
      color: #c4a994;
      :global {
        .feedbackSuggestion__control {
          background-color: #ededed;
          border-color: #ededed;
        }
        .feedbackSuggestion__input-container {
          color: #000;
        }
        .feedbackSuggestion__single-value {
          color: #000;
        }
      }
      .textArea,
      .contactInfo input {
        background-color: #ededed;
        border: none;
        caret-color: #000;
        color: #000;
      }
      .modalHeader {
        background-image: url("../../../assets/brownGold/other_modal/OtherModalHeader.png");
      }
      .modalBody {
        background: #fff;
      }
    }
    &[data-theme="yellowWhite"] {
      color: #c5915a;
      :global {
        .feedbackSuggestion__control {
          background-color: #fefeff;
          border-color: #fefeff;
        }
        .feedbackSuggestion__input-container {
          color: #511a00;
        }
        .feedbackSuggestion__single-value {
          color: #511a00;
        }
        .feedbackSuggestion__placeholder {
          color: #c5915a;
        }
      }
      .textArea,
      .contactInfo input {
        background-color: #fefeff;
        border: none;
        caret-color: #000;
        color: #511a00;
        &::placeholder {
          color: #c5915a;
        }
      }
      .modalHeader {
        background-image: url("../../../assets/yellowWhite/other_modal/OtherModalHeader.png");
      }
      .modalBody {
        background: #ebedf1;
      }
    }
    &[data-theme="greenYellow"] {
      color: #511a00;
      :global {
        .feedbackSuggestion__control {
          background-color: #fff;
          border-color: #fff;
        }
        .feedbackSuggestion__input-container {
          color: #511a00;
        }
        .feedbackSuggestion__single-value {
          color: #511a00;
        }
        .feedbackSuggestion__placeholder {
          color: #757575;
        }
      }
      .textArea,
      .contactInfo input {
        background-color: #fff;
        border: none;
        caret-color: #000;
        color: #511a00;
        &::placeholder {
          color: #757575;
        }
      }
      .modalHeader {
        background-image: url("../../../assets/greenYellow/other_modal/OtherModalHeader.png");
      }
      .modalBody {
        background: #d6eae4;
      }
    }
    &[data-theme="skyBlue"] {
      color: #0ca0e9;
      :global {
        .feedbackSuggestion__control {
          background-color: #fff;
          border-color: #fff;
        }
        .feedbackSuggestion__input-container {
          color: #0ca0e9;
        }
        .feedbackSuggestion__single-value {
          color: #0ca0e9;
        }
        .feedbackSuggestion__placeholder {
          color: #bbbbbb;
        }
      }
      .textArea,
      .contactInfo input {
        background-color: #fff;
        border: none;
        caret-color: #000;
        color: #0ca0e9;
        &::placeholder {
          color: #bbbbbb;
        }
      }
      .modalHeader {
        background-image: url("../../../assets/skyBlue/other_modal/OtherModalHeader.png");
        span {
          color: #fff;
          text-shadow: unset;
        }
      }
      .modalBody {
        background: #f4f6f9;
      }
    }
    &[data-theme="darkBlue"] {
      color: #4d6296;
      :global {
        .feedbackSuggestion__control {
          background-color: #fff;
          border-color: #fff;
        }
        .feedbackSuggestion__input-container {
          color: #162752;
        }
        .feedbackSuggestion__single-value {
          color: #162752;
        }
        .feedbackSuggestion__placeholder {
          color: #4d6296;
        }
      }
      .textArea,
      .contactInfo input {
        background-color: #fff;
        border: none;
        caret-color: #000;
        color: #162752;
        &::placeholder {
          color: #4d6296;
        }
      }
      .modalHeader {
        background-image: url("../../../assets/darkBlue/other_modal/OtherModalHeader.png");
        span {
          color: #162752;
          text-shadow: 0 4px 4px #fff;
        }
      }
      .modalBody {
        background: linear-gradient(270deg, #29334b 0%, #4d6296 100%);
        .contactInfo {
          span {
            color: #f0cb5a;
          }
        }
      }
      .button {
        background-image: url("../../../assets/darkBlue/fragments/button2.png");
        span {
          color: #162752;
        }
      }
    }
    &[data-theme="redGold"] {
      color: #f0cb5a;
      :global {
        .feedbackSuggestion__control {
          background-color: #fff;
          border-color: #fff;
        }
        .feedbackSuggestion__input-container {
          color: #000;
        }
        .feedbackSuggestion__single-value {
          color: #000;
        }
        .feedbackSuggestion__placeholder {
          color: #757575;
        }
        .feedbackSuggestion__menu {
          color: #000;
        }
      }
      .textArea,
      .contactInfo input {
        background-color: #fff;
        border: none;
        caret-color: #000;
        color: #000;
        &::placeholder {
          color: #757575;
        }
      }
      .modalHeader {
        background-image: url("../../../assets/redGold/other_modal/OtherModalHeader.png");
      }
      .modalBody {
        background: linear-gradient(90deg, #af0100 0%, #ff6b00 100%);
        border: 0.01rem solid #8d7c4f;
      }
    }
    &[data-theme="whiteGold"] {
      color: #435689;
      :global {
        .feedbackSuggestion__control {
          background-color: #fff;
          border-color: #fff;
        }
        .feedbackSuggestion__input-container {
          color: #435689;
        }
        .feedbackSuggestion__single-value {
          color: #435689;
        }
        .feedbackSuggestion__placeholder {
          color: #757575;
        }
        .feedbackSuggestion__menu {
          color: #435689;
        }
      }
      .textArea,
      .contactInfo input {
        background-color: #fff;
        border: none;
        caret-color: #435689;
        color: #435689;
        &::placeholder {
          color: #757575;
        }
      }
      .modalHeader {
        background-image: url("../../../assets/whiteGold/other_modal/OtherModalHeader.png");
      }
      .modalBody {
        background: #dadce7;
      }
    }
  }
}
